import React, {useContext} from 'react';
import styled from "styled-components";
import Font from "../components/Font";
import {useTranslation} from "react-i18next";
import ScreenWidths from "../constants/ScreenWidths";
import GhostButton from "../components/GhostButton";
import {useNavigate} from "react-router-dom";
import {useQueryRequest} from "../hooks/UseJsonApi";
import {AccountContext} from "../../components/AccountContext";
import Table from "../components/Table";
import Spinner from "../components/Spinner";
import NoData from "../components/NoData";

const StyHomeTransactions = styled.div`
    
    @media (width > ${ScreenWidths.xs}) {
        margin-left: 24px;
    }
    
    margin: 0 8px;
    > :nth-child(1) {
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (width < ${ScreenWidths.xs}) {
            :nth-child(2) {
                display: none;
            }
            :nth-child(1) {
                margin-top: 16px;
            }
        }
    }

   .more-transactions {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (width > ${ScreenWidths.xs}) {
            display: none;
        }
    }
`;

const HomeTransactions = (props) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const { account } = useContext(AccountContext);

    const transactions = useQueryRequest({
        queryKey: ["HomeTransactions", account],
        url: `/cardholder/accounts/v2/${account?.id}/transactions?page=0&size=5`,
        enabled: !!account
    })

    return (
        <StyHomeTransactions {...props}>

            <div>
                <Font variant={"lb"}>{t("Latest transactions")}</Font>
                <GhostButton label={t("See more transactions")} onClick={() => navigate("/transactions")} icon={"/images/icons/arrow-narrow-right.png"}/>
            </div>

            {transactions.isLoading ? <Spinner/> :
                transactions.data?.length > 0
                ?
                <Table
                    items={transactions.data}
                    cellValues={["transactionDate","description","settlementAmount.amount"]}
                />
                : <NoData/>
            }

            <div className={"more-transactions"}>
                <GhostButton label={t("See more transactions")} onClick={() => navigate("/transactions")} icon={"/images/icons/arrow-narrow-right.png"}/>
            </div>

        </StyHomeTransactions>
    )
};

export default HomeTransactions;