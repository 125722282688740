import React, {useEffect, useState} from 'react';
import styled from "styled-components";

const StyExpandableRow = styled.div`
    cursor: pointer;
    > :nth-child(1) {
        > div {
            width: 100%;
        }
        padding: 12px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
            height: ${props => props.bigIcon ? "26px" : "22px"};
            width:  ${props => props.bigIcon ? "26px" : "22px"};
            filter: brightness(0%);
        }
    }
`;

const ExpandableRow = (props) => {

    const [expanded, setExpanded] = useState(false);
    const [id, setId] = useState()

    useEffect(() => {
        props.defaultExpanded && setExpanded(true)
        setId(Math.floor(Math.random() * Date.now()));
    },[])

    useEffect(() => {
        expanded
            ? setExpanded(false)
            : (props.expandedId && props.expandedId === id) && setExpanded(true)
    },[props.expandedId])


    return (
        <StyExpandableRow {...props}>
            <div onClick={() => { props.setExpandedId( !expanded ? id : null); props.onOpen && props.onOpen() }}>
                <div>{props.content}</div>
                <img src={ expanded ? "images/icons/arrow-up.png" : "images/icons/arrow_down.png"} alt={"expand"}/>
            </div>
            <div style={{ display: !expanded && "none"}}>
                {props.expanded}
            </div>
        </StyExpandableRow>
    )
};

export default ExpandableRow;