import React from 'react';

export const truncateStr = (str, max) => {
    if ( str === undefined) {
        return ""
    } else {
        return str.length > max
            ? ((str.replace(/\s+/g, ' ').trim()).substring(0, max)) + ".."
            : str
    }
};

export const capitalize = (str) => {
    if ( !str ) {
        return ""
    } else {
        return str?.charAt(0).toUpperCase() + str?.substring(1).toLowerCase()
    }
}

export const svCurrencyCents = (number) => {
    if ( isNaN(number)) {
        return ""
    } else {
        return new Intl.NumberFormat('sv', {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 2
        }).format(number/10)
    }
}

export const svCurrencyKr = (number) => {
    if ( isNaN(number)) {
        return ""
    } else {
        return new Intl.NumberFormat('sv', {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 2
        }).format(number)
    }
}

export const svDate = (date) => {
    if ( date === undefined) {
        return ""
    } else {
        return  new Date(date).toLocaleDateString("sv")
    }
}