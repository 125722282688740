import React from 'react';
import styled from "styled-components";
import {Link, NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Colors from "../constants/Colors";
import * as jwt from "jsonwebtoken";
import GhostButton from "../components/GhostButton";

const StyDesktopMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    box-shadow: 0 10px 14px 1px #eeeeee;

    ul {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
    }

    li {
        display: flex;
        margin: 6px 4px;
        list-style: none;
        font-size: 14px;

        a, > div {
            border-radius: 18px / 50%;
            padding: 8px 16px;
            border: 2px solid ${Colors.greyLightThree};
            align-items: center;
            display: flex;
            text-decoration: none;
            color: #30363F;

            button {
                margin-left: 16px;
            }

        }

        img {
            height: 18px;
            margin-right: 4px;
        }

        .active {
            font-weight: 600;
            color: #3B9CDB;
            border-color: #3B9CDB;

            img {
                filter: invert(68%) sepia(52%) saturate(4042%) hue-rotate(176deg) brightness(90%) contrast(90%);
            }
        }
    }
`;

const DesktopMenu = (props) => {
    const { t } = useTranslation();
    const decoded = jwt.decode(localStorage.getItem('auth-token'));

    return (
        <StyDesktopMenu {...props}>
            <Link to={"home"}>
                <img src={"/images/icons/epassi-logo.svg"} alt="home"/>
            </Link>
            <ul>
                <li>
                    <NavLink to={"support"}>
                        <img src={"/images/icons/question-mark.png"} alt="support"/>
                        {t("Support")}
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"settings"}>
                        <img src={"/images/icons/settings.png"} alt="settings"/>
                        {t("My settings")}
                    </NavLink>
                </li>
                <li>
                    <div>
                        <img src={"/images/icons/user.svg"} alt="user icon"/>
                        <div>{decoded?.userDetails?.firstName + " " + decoded?.userDetails?.lastName}</div>
                        <GhostButton label={t("Log out")} onClick={()=> {localStorage.removeItem('auth-token'); window.location.reload()}}/>
                    </div>
                </li>
            </ul>
        </StyDesktopMenu>
    )
};

export default DesktopMenu;