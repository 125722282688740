import React, {useContext, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useQueryRequest} from "../hooks/UseJsonApi";
import {SetNotificationValue} from "../hooks/NotificationContext";
import HomeBanner from "../components/HomeBanner";
import {AccountContext} from "../../components/AccountContext";
import AuthPopup from "../components/AuthPopup";

const StyUnblockPin = styled.div`
    padding: 8px 8px 24px 8px;
    cursor: pointer;
    
`;

const UnblockPin = (props) => {

    const {t} = useTranslation()
    const {setNotification} = useContext(SetNotificationValue);
    const { card } = useContext(AccountContext);
    const [authenticated, setAuthenticated] = useState(false)
    const [openAuth, setOpenAuth] = useState(false)

    const resetPin = useQueryRequest({
        url:"/cardholder/cards/v2/" + card?.referenceCode + "/reset-pin-attempt",
        queryKey: ["UnblockPin"],
        enabled: authenticated,
        onSuccess: (data) => {
            setNotification({type: "success", message: t("PIN unblocked, you can now resume using your card")});
            setAuthenticated(false)
        },
        onError: () => {
            setNotification({type: "error", message: t("Unable to reset PIN for this card")});
        }
    })

    return (
        <StyUnblockPin {...props}>

            <HomeBanner
                onClick={() => setOpenAuth(true)}
                variant={"error"}
                title={t("Unblock my PIN")}
                content={t("Your PIN has ben blocked due too many failed attempts. Authenticate yourself to unblock the card PIN")}
            />

            <AuthPopup
                open={openAuth}
                setOpen={setOpenAuth}
                setAuthenticated={setAuthenticated}
                title={t("Authenticate to unblock")}
                subTitle={t("You need to authenticate yourself to unblock the card PIN. After successful authentication the card will work right away.")}
            />

        </StyUnblockPin>
    )
};

export default UnblockPin;