import React, {useContext, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Font from "../components/Font";
import Tooltip from "../components/Tooltip";
import {useQueryRequest} from "../hooks/UseJsonApi";
import {AccountContext} from "../../components/AccountContext";
import Popup from "../components/Popup";
import Colors from "../constants/Colors";
import UseMediaQuery from "../hooks/UseMediaQuery";
import ScreenWidths from "../constants/ScreenWidths";
import RadioGroup from "../components/RadioGroup";
import {capitalize} from "../../components/HelperFunctions";


const StyCardPreview = styled.div`
    margin: 0 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .header {
        display: flex;
        align-items: center;
        align-self: flex-start;
        margin-bottom: 12px;
    }
    
    img {
        margin: 4px;
        height: 20px;
        width: 20px;
    }
    
    .card{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 16px;
        border-radius: 8px;   
        height: 190px;
        background: linear-gradient(to top right, #386CC7, #6931C6, #BB2390, #E84B4A);
        
        .switch-account {
            padding-right: 4px;
            color: #F1F3F5;
            font-size: 20px;
            font-weight: 600;
            border: none;
            background-color: transparent;
        }

        .switch-card {
            padding-right: 40px;
            margin: 12px 0;
            color: #fff;
            letter-spacing: 4px;
            font-size: 16px;
            border: none;
            background-color: transparent;
        }
        
        select {
            cursor: pointer;
        }
    }
    
    .bottom {
        display: flex;
        justify-content: space-between;
        p {
            padding: 2px 4px
        }
    }
    
    form {
        option {
            font-weight: 400;
            color: #000;
            font-size: 15px;
        }
    }
    
    .tool-tip {
        line-height: 24px;
        color: ${Colors.greyDarkThree}
    }
`;

const CardPreview = (props) => {
    const {t, i18n} = useTranslation()
    const isSmallDevice = UseMediaQuery(`(width < ${ScreenWidths.xs})`);
    const { account, setAccount, card, setCard } = useContext(AccountContext);

    const [infoPop, setInfoPop] = useState(false)
    const [selectBranchPop, setSelectBranchPop] = useState(false)
    const [radioGroupEmployee, setRadioGroupEmployee] = useState()
    const [selectCardPop, setSelectCardPop] = useState(false)
    const [radioGroupCard, setRadioGroupCard] = useState()

    const allAccounts = useQueryRequest({
        queryKey: ["CardPreview", {runOnce: true} ],
        url:"/cardholder/accounts/v2",
        enabled: !!account,
    })

    const accountBalance = useQueryRequest({
        queryKey: ["CardPreview", account],
        url: `/cardholder/accounts/v2/${account?.id}/balance`,
        enabled: !!account
    })

    const accountCards = useQueryRequest({
        queryKey: ["CardPreview", account],
        url:`/cardholder/accounts/v2/${account?.id}/cards`,
        enabled: !!account
    })

    const nextTopupInfo = useQueryRequest({
        queryKey: ["CardPreview"],
        url:`/cardholder/topups/${account?.id}/next-topup-info`,
        enabled: !!account
    })


    return (
        <StyCardPreview {...props}>

            <div className={"header"} onClick={() => setInfoPop(true)}>
                <Font variant={"lb"}>{t("Active Benefit cards")}</Font>

                <Tooltip width={"280px"} label={t("If you have multiple employers or benefit cards, you can view information from them by tapping on the employer name or the card number and selecting a different one.")}>
                    <img src={"/images/icons/exclamation-mark.svg"} alt="support"/>
                </Tooltip>
            </div>

            { isSmallDevice &&
                <Popup open={infoPop} title={"Switching between cards"} rightBtAction={() => setInfoPop(false)} rightBtLabel={t("close")}>
                    <p className={"tool-tip"}>
                        {t("If you have multiple employers or benefit cards, you can view information from them by tapping on the employer name or the card number and selecting a different one.")}
                    </p>
                </Popup>
            }

            <div className={"card"}>
                <div>
                    { allAccounts.data?.length > 1
                        ?
                        isSmallDevice
                            ?
                            <>
                                <div style={{display: "flex", alignItems: "center"}} onClick={() => setSelectBranchPop(true)}>
                                    <span className={"switch-account"}>{account?.topupLevel?.branchName}</span>
                                    <img src={"images/icons/arrow_down.png"} alt={"arrow down"}/>
                                </div>
                                <Popup
                                    open={selectBranchPop}
                                    title={"Select employer"}
                                    rightBtLabel={t("save")}
                                    rightBtAction={() => {
                                        setAccount(allAccounts.data?.find(i => i.id.toString() === radioGroupEmployee));
                                        setSelectBranchPop(false)
                                    }}
                                >
                                    <RadioGroup
                                        array={allAccounts.data}
                                        onChange={(id) => {setRadioGroupEmployee(id)}}
                                        defaultValue={account?.id}
                                        idValue={"id"}
                                        label={"topupLevel.branchName"}
                                    />
                                </Popup>
                            </>
                            :
                            <form>
                                <select
                                    className={"switch-account"}
                                    onChange={(e) => {setAccount(allAccounts.data?.find(i => i.id.toString() === e.target.value))}}
                                    defaultValue={account?.id}
                                >
                                    { allAccounts.data?.map(item => (
                                        <option  key={item.id} value={item.id}>{item.topupLevel?.branchName}</option>
                                    )) }
                                </select>
                            </form>

                        :
                        <p className={"switch-account"}>{account?.topupLevel?.branchName}</p>
                    }

                    <p className={"switch-card"}>{card?.referenceCode}</p>

                </div>

                <div className={"bottom"}>
                    <div>
                        <Font color={"#fff"}>{t("Your balance")}</Font>
                        <Font variant={"xlb"} color={"#fff"}>{
                            t(accountBalance.data?.amount
                                ? new Intl.NumberFormat(i18n.language).format(accountBalance.data?.amount)
                                : "0 kr")
                        }</Font>
                    </div>
                    <div>
                        <Font color={"#fff"}>
                            <span>{t("Next topup") + " "}</span>
                            {
                                nextTopupInfo?.data?.nextTopupDate
                                    ?
                                    (() => {
                                        let nextTopupDate = new Date(nextTopupInfo?.data?.nextTopupDate);
                                        return ( String(nextTopupDate.getDate())?.padStart(2, "0") + " " + capitalize(nextTopupDate.toLocaleDateString(i18n.language === 'sv' ? "sv" : "en", {month: 'short'})).substring(0, 3))
                                    })()
                                    :
                                    <div style={{minWidth: "140px"}}></div>
                            }
                        </Font>
                        {
                            nextTopupInfo?.data?.displayMaxTopupAmount && nextTopupInfo.data.displayMaxTopupAmount === true ? 
                            (nextTopupInfo?.data?.actualTopupAmount && nextTopupInfo.data.actualTopupAmount !== null ? 
                                <Font variant={"xlb"} color={"#fff"}>{ nextTopupInfo.data?.actualTopupAmount/100 + " kr"}</Font>: 
                                <Font variant={"xlb"} color={"#fff"}> {t("upto")} { nextTopupInfo.data?.maxTopupAmount/100 + " kr"}</Font>)
                            :
                            <Font variant={"xlb"} color={"#fff"}>{ nextTopupInfo?.data?.maxTopupAmount ? nextTopupInfo.data.maxTopupAmount/100 + " kr"   : "0.00 kr"}</Font>
                        }

                    </div>
                </div>

            </div>
        </StyCardPreview>
    )
};

export default CardPreview;