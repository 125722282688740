import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { initReactI18next } from "react-i18next";

import translationEn from "./locales/cardholder_en.json"
import translationSv from "./locales/cardholder_sv.json"

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we initialize with resources
        resources: {
            en: {
                translations: translationEn
            },
            sv: {
                translations: translationSv
            }
        },

        lng: "sv",
        language: "en",
        fallbackLng: "en",
        debug: false,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: true, // we use content as keys

        interpolation: {
            escapeValue: false
        }

    });

export default i18n;
