import React, {useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import InputWrapper from "../components/InputWrapper";
import {useQueryRequest} from "../hooks/UseJsonApi";
import {useNavigate} from "react-router-dom"
import InputErrorMsg from "../components/InputErrorMsg";

const StyPersonalInformation = styled.div`
    > p {
        font-weight: 600;
        margin-bottom: 12px;
    }
    
    .verification-message {
        display: flex;
        margin-bottom: 8px;
        
        .verify-link {
            text-decoration: underline;
            cursor: pointer;
            font-weight: 600;
            color: #1479C4
        }
    }
    
`;

const PersonalInformation = (props) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [unverifiedContactInfo, setUnverifiedContactInfo] = useState(null)
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()

    const getPersonalInfo = useQueryRequest({
        queryKey: ["getPersonalInfoData"],
        url:"/cardholder/info",
        onSuccess: (data) => {
            setPhone(data?.personalInformation?.contactInfos.find(a => a.contactInformationType.descriptionEnglish === "Phone"))
            setEmail(data?.personalInformation?.contactInfos.find(a => a.contactInformationType.descriptionEnglish === "Email"))
        }
    })

    const requestVerificationCode = useQueryRequest({
        url:"/cardholder/contact-info/verification-request",
        type: "POST",
        queryKey: ["personalInformationVerify"],
        enabled: !!unverifiedContactInfo,
        body: {
            "contactInformationId": unverifiedContactInfo?.id,
            "contactInformationTypeId": unverifiedContactInfo?.contactInformationType.id,
            "newValue": unverifiedContactInfo?.description
        },
        onSuccess: (data) => {
            navigate("/verification-code", {
                state: {
                    contactInformationId: unverifiedContactInfo?.id,
                    value: unverifiedContactInfo?.description,
                    contactInformationType: unverifiedContactInfo?.contactInformationType.id
                }
            })
        }
    })


    return (
        <StyPersonalInformation {...props}>

            <p>{t("Personal information")}</p>

            <InputWrapper label={t("First name")} slim={"true"}>
                <input value={getPersonalInfo.data?.personalInformation.firstName || ''} disabled />
            </InputWrapper>

            <InputWrapper label={t("Last name")} slim={"true"}>
                <input value={getPersonalInfo.data?.personalInformation.lastName || ''} disabled/>
            </InputWrapper>

            <InputWrapper
                slim={"true"}
                label={t("Phone number")}
                value={phone?.description || " "}
                onEdit={() => navigate("/edit-phone")}
            >
                <input value={phone?.description || ""} readOnly />
            </InputWrapper>

            { (phone?.verified === false && phone?.description !== "")  &&
                <div className={"verification-message"}>
                    <InputErrorMsg label={t("Unverified.")}/>
                    &nbsp;
                    <div onClick={() => setUnverifiedContactInfo(phone)} className={"verify-link"}>{t("Verify number")}</div>
                </div>
            }

            <InputWrapper
                slim={"true"}
                label={t("Email")}
                value={email?.description || " "}
                onEdit={() => navigate("/edit-email")}
            >
                <input value={email?.description || " "} readOnly/>
            </InputWrapper>

            { (email?.verified === false && email?.description !== "") &&
                <div className={"verification-message"}>
                    <InputErrorMsg label={t("Unverified.")}/>
                    &nbsp;
                    <div onClick={() => setUnverifiedContactInfo(email)} className={"verify-link"}>{t("Verify email")}</div>
                </div>
            }

            <InputWrapper label={t("Address")} slim={"true"}>
                <input
                    value={getPersonalInfo.data?.personalInformation?.addresses[0].addressLine1 || ''} disabled
                />
            </InputWrapper>

            <InputWrapper label={t("Postal code")} slim={"true"}>
                <input
                    value={getPersonalInfo.data?.personalInformation?.addresses[0].postalCode || ''} disabled
                />
            </InputWrapper>

        </StyPersonalInformation>
    )
};

export default PersonalInformation;