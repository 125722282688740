import React, {useContext, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {SetNotificationValue} from "../hooks/NotificationContext";
import {useQueryRequest} from "../hooks/UseJsonApi";
import Spinner from "../components/Spinner";
import ToogleWrapper from "../components/ToggleWrapper";

const StySubscribeTopups = styled.div`
    max-width: 488px;
    .subscribeTopups {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 8px 0 12px 0;
        align-items: center;
        
        .fonts {
            :nth-child(1) {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 6px;
            }
        }
        
        .toggle {
            margin-left: 24px;
        }
        
    }
`;

const SubscribeTopups = (props) => {
    const {t} = useTranslation()
    const {setNotification} = useContext(SetNotificationValue);
    const [isSubscribed, setIsSubscribed] = useState(null)

    const getIfSubscribed = useQueryRequest({
        queryKey: ["getIfSubscribed"],
        url:"/cardholder/preferences",
        enabled: isSubscribed === null
    })

    const setIfSubscribed = useQueryRequest({
        queryKey: ["setIfSubscribed", isSubscribed],
        url:"/cardholder/preferences",
        type: "POST",
        body: {subscribeTopups: isSubscribed},
        enabled: isSubscribed !== null,
        onSuccess: (data) => {
            setNotification({type: "success", message: t("Automatic topup preferences saved successfully")});
            setIsSubscribed(data.preferences.subscribeTopups);
            props.subcribedChanged()
        }
    })

    return (
        <StySubscribeTopups {...props}>

            { setIfSubscribed.isFetching
                ?
                <Spinner height={"60px"} margin={"24px 0"}/>
                :
                <div className={"subscribeTopups"}>
                    <div className={"fonts"}>
                        <p>{t("Automatic top-ups")}</p>
                        <p>{t("Your top-ups will be ordered automatically every month")}</p>
                    </div>
                    <div className={"toggle"}>
                        <ToogleWrapper>
                            <input
                                type="checkbox"
                                checked={isSubscribed === null ? getIfSubscribed?.data?.subscribeTopups : isSubscribed}
                                onChange={ e => setIsSubscribed(e.target.checked)}
                            />
                        </ToogleWrapper>
                    </div>
                </div>
            }

        </StySubscribeTopups>
    )
};

export default SubscribeTopups;