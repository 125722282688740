import React, {useState} from 'react';
import styled from "styled-components";
import Font from "../components/Font";
import {useTranslation} from "react-i18next";
import Colors from "../constants/Colors";
import ScreenWidths from "../constants/ScreenWidths";
import Fonts from "../constants/Fonts";
import CalculatorPopup from "./CalculatorPopup";
import {matchRoutes, useLocation} from "react-router-dom";


const Footer = (props) => {
    const location = useLocation()

    const showNavigation = matchRoutes([
        {path: "support"},
        {path: "settings/*"},
        {path: "/edit-phone"},
        {path: "/edit-email"},
        {path: "/verify-email"},
        {path: "/replacement-card"}
    ], location)

    const StyFooter = styled.div`
        @media (width < ${ScreenWidths.xs}){
            margin-bottom: ${!showNavigation && "72px"};
        }
        
        .top {
            background-color: ${Colors.greyLightOne};
            padding: 12px 12px 8px 12px;
            & img {
                margin: 8px 4px 0 4px;
            }
            & :nth-child(1) {
                margin-bottom: 4px;
            }
        }
        .bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 12px;
            background-color: ${Colors.greyLightTwo};
            div { 
                ${Fonts.sb}
            }
        }
        a { 
            color: inherit
        }
    `;

    const { t } = useTranslation();
    const [openPopup, setOpenPopup] = useState(false)

    return (
        <StyFooter {...props}>
            <div className={"top"}>
                <Font variant={"s"} color={"greyDarkTwo"} label={t("This Visa card is issued by Enfuce pursuant to licence by VISA Europe Limited")}/>
                <Font variant={"rb"} color={"greyDarkTwo"} label={t("Download the Epassi Card app")}/>
                <div className={"stores"}>
                    <a target="_blank" href={"https://play.google.com/store/apps/details?id=se.sodexo.lunchkultur&hl=sv"}>
                        <img src={"/images/icons/google_play.svg"} alt="google play"/>
                    </a>
                    <a target="_blank" href={"https://apps.apple.com/se/app/lunchkultur/id1247868623"}>
                        <img src={"/images/icons/app_store.svg"} alt="Apple store"/>
                    </a>
                </div>
            </div>
            <div className={"bottom"}>
                <Font variant={"s"} color={"greyDarkThree"} label={"Epassi Benefits & Rewards Sweden AB. Org.nr: 556649-1444"}/>
                <a target="_blank" href={"https://www.epassi.se/sv/lunchkort/terms-conditions-and-privacy-notice"}>
                    <div>{t("Terms and Conditions & Privacy notice")}</div>
                </a>
                <a href={"javascript:undefined"}>
                    <div onClick={() => setOpenPopup(true)}>{t("FX calculator")}</div>
                </a>
            </div>

            <CalculatorPopup openPopup={openPopup} setOpenPopup={ (bool) => setOpenPopup(bool)}/>

        </StyFooter>
    )
};

export default Footer;