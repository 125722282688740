import React, {useContext, useEffect} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useQueryRequest} from "../hooks/UseJsonApi";
import InputWrapper from "../components/InputWrapper";
import Button from "../components/Button";
import CheckboxWrapper from "../components/CheckboxWrapper";
import {AccountContext} from "../../components/AccountContext";
import {SetNotificationValue} from "../hooks/NotificationContext";
import SelectWrapper from "../components/SelectWrapper";
import {useForm} from "react-hook-form";

const StyReplacementCardForm = styled.div`
    margin: 0 auto;
    max-width: 488px;

    p {
        font-size: 14px;
    }
;

    > :nth-child(1) {
        h1 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 8px;
        }
        > button {
            text-decoration: none;
            &:hover {
                cursor: pointer;

            };
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 2px solid #D1D5D7;
            padding: 8px;
            border-radius: 24px;
            div {
                text-align: left;
                :nth-child(1) {
                    font-size: 16px;
                    margin-bottom: 6px;
                    font-weight: 600;
                    color: #6C10B9
                };
                :nth-child(2) {
                    font-size: 14px;
                    color: #4D5D70
                };
            }
            img {
                height: 14px;
                width: 14px;
            }
        }
    }

    > form {
        margin: 16px 0 46px 0;
        
        > :nth-child(1){
            margin-bottom: 8px;
        }
        
        > p {
            margin: 16px 0 0 0;
            font-size: 16px;
            color: #2992d5;
            font-weight: 600;
        }
    }

    > :nth-child(3) {
        margin: 16px 0;
        h3 {
            margin-bottom: 8px;
            font-size: 18px;
            font-weight: 600;
        }

        p {
            margin: 8px 0
        }
    }

`;

const ReplacementCardForm = (props) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {card} = useContext(AccountContext);
    const {setNotification} = useContext(SetNotificationValue);
    const { register, handleSubmit, watch, formState: { errors, isValid, isSubmitted, isDirty}, reset, resetField, getValues, setValue, setError } = useForm({ mode: "all"});

    useEffect(() => {
       !card && navigate("/card")
    },[])

    const getPersonalInfo = useQueryRequest({
        url:"/cardholder/info/",
        queryKey: ["ReplacementCardForm"],
    })

    const getReplacementFee = useQueryRequest({
        url:"/cardholder/cards/replacement/fees",
        queryKey: ["ReplacementCardForm"]
    })

    const replaceCard = useQueryRequest({
        type: "POST",
        url:"/cardholder/cards/" + card?.referenceCode + "/replace",
        queryKey: ["ReplacementCardForm"],
        enabled: isSubmitted && isDirty,
        body: {
            reasonId: Number(getValues("replaceReason")),
            feeAmount: watch("replaceReason") ? getReplacementFee.data.filter(item => item.id === Number(watch("replaceReason")))[0]?.fee.amount * 100 : 0,
            changedAmount: false
        },
        onSuccess: () => {
            reset();
            setNotification({
                type: 'success',
                message: t("Thank you for your order, the card will be sent to your registered address"),
            });
        },
        onError: () => {
            reset();
            setNotification({
                type: 'error',
                message: t("Could not fetch data from the server!"),
            });
        }
    })

    return (
        <StyReplacementCardForm {...props}>
            <div>
                <button onClick={() => navigate(-1)}>
                    <img src={"/images/icons/back-arrow.svg"} alt={"go to page"}/>
                </button>

                <h1>{t("Block and order replacement card")}</h1>

                <p>{t("If you have lost your card or it is stolen, you can easily block it here and order a new replacement card at the same time. A fee may be deducted from your balance. To order a replacement card, you need to start by choosing the reason why you need a new card.")}</p>
            </div>

            <form>
                <SelectWrapper error={errors.replaceReason} slim>
                    <select {...register("replaceReason", {required: t("This field is required")})} defaultValue={""}>
                        <option value="" disabled selected hidden>{t("Select replacement reason")}</option>
                        { getReplacementFee.data?.filter( item => item.hidden === false)?.map(item => (
                            <option key={item.id} value={item.id}>{t(item.descriptionEnglish)}</option>
                        )) }
                    </select>
                </SelectWrapper>

                {
                    watch("replaceReason") &&
                        <>
                            <p>{t("The replacement fee is") + ": " }  {watch("replaceReason") ? getReplacementFee.data.filter(item => item.id === Number(watch("replaceReason")))[0]?.fee?.amount : 0} {" kr"}</p>

                            <CheckboxWrapper label={t("I approve the fee for my replacement card")} error={errors.replacementApproval}>
                                <input type="checkbox" {...register("replacementApproval", {required: t("This field is required")} )}/>
                            </CheckboxWrapper>
                        </>
                }
            </form>

            <div>
                <h3>{t("Delivery address for your new card")}</h3>
                <p>{t("The card is sent to your civil registration address which is obtained from SPAR/Skatteverket.")}</p>
                <p>{t("If the address is not correct, contact the Swedish Tax Agency in the first instance. The service is free of charge and your address is automatically updated with us.")}</p>

                <InputWrapper label={t("Address")} slim={"true"}>
                    <input
                        value={getPersonalInfo.data?.personalInformation?.addresses[0].addressLine1 || ''} disabled
                    />
                </InputWrapper>

                <InputWrapper label={t("Postal code")} slim={"true"}>
                    <input
                        value={getPersonalInfo.data?.personalInformation?.addresses[0].postalCode || ''} disabled
                    />
                </InputWrapper>

                <InputWrapper label={t("City")} slim={"true"}>
                    <input
                        value={getPersonalInfo.data?.personalInformation?.addresses[0].city || ''} disabled
                    />
                </InputWrapper>
            </div>

            <Button disabled={!isValid} loading={replaceCard.isFetching} type={"submit"} onClick={handleSubmit(() => {})}>
                <div>{t("Order replacement card")}</div>
            </Button>



        </StyReplacementCardForm>
    )
};

export default ReplacementCardForm;