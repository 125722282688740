import React, {useContext} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Colors from "../constants/Colors";
import {NavLink} from "react-router-dom";
import {AccountContext} from "../../components/AccountContext";

const StyMobileNavigation = styled.nav`
    display: flex;
    align-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFF;
    box-sizing: border-box;
    border-top: solid 2px ${Colors.greyLightTwo};
    z-index: 2000;
    
    & ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        margin: 0;
    }
    
    & li {
        display: flex;
        flex-direction: column;
        align-content: center;
        padding: 0;
        margin: 0;
        list-style: none;
        & > a {
            font-size: 12px;
            text-decoration: none;
            color: inherit;
            opacity: 0.6;
        }
        & > * {
            text-align: center;
        }
        .active {
            opacity: 1.0
        }
    }

    & img {
        height: 24px;
        width: 100%;
    }
`;

const MobileNavigation = (props) => {
    const { t } = useTranslation();

    const { disableCalendar } = useContext(AccountContext);

    return (
        <StyMobileNavigation {...props}>
            <ul>
                <li>
                    <NavLink to={"home"}>
                        <img src={"/images/icons/home.svg"} alt="home"/>
                        {t("Home")}
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"transactions"}>
                        <img src={"/images/icons/transactions.svg"} alt="transactions"/>
                        {t("Transactions")}
                    </NavLink>
                </li>
                {
                    !disableCalendar &&
                    <li>
                        <NavLink to={"topup"}>
                            <img src={"/images/icons/top-up.svg"} alt="top ups"/>
                            {t("Top-ups")}
                        </NavLink>
                    </li>
                }
                <li>
                    <NavLink to={"card"}>
                        <img src={"/images/icons/credit-card.svg"} alt="credit card"/>
                        {t("Card")}
                    </NavLink>
                </li>
            </ul>
        </StyMobileNavigation>
    )
};

export default MobileNavigation;