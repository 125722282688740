import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import MyCardCardPreview from "./MyCardsCardPreview";
import ReplacementCard from "./ReplacementCard";
import TemporaryBlock from "./TemporaryBlock";

const StyCard = styled.div`
    margin: 0 auto;
    max-width: 340px;
    > p  {
        font-size: 20px;
        font-weight: 600;
        margin: 4px 0;
        min-height: 24px;
    }
    
`;

const Card = (props) => {
    const {t} = useTranslation()

    return (
        <StyCard {...props}>

            <p>{t("My cards")}</p>

            <MyCardCardPreview/>

            <ReplacementCard/>

            <TemporaryBlock/>

        </StyCard>
    )
};

export default Card;