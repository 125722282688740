import React from 'react';
import styled, {css} from "styled-components";

const pageSpinner = css`
    > section {
        text-align: center;
        margin: ${props => props.margin ? props.margin : "24px 0"};
        height: 24px;
    }
    .loader {
        aspect-ratio: 1/1;
        height: 100%;
        border: 4px solid  ${props => props.color ? props.color : "#3B9CDB"};
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
    .loader::after {
        content: '';
        position: absolute;
        box-sizing: border-box;
        left: 45%;
        top: 80%;
        border-right-color: ${props => props.color ? props.color : "#3B9CDB"};
        transform: rotate(-70deg);
    }
`;

const buttonSpinner = css`
    > section {
        text-align: center;
        margin: 0;
        height: 20px; 
    }
    
    .loader {
        aspect-ratio: 1/1;
        height: 100%;
        border: 3px solid #fff;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    .loader::after {
        content: '';
        position: absolute;
        box-sizing: border-box;
        left: 45%;
        top: 80%;
        transform: rotate(-70deg);
    }
`;


const StySpinner = styled.div`
    height: ${props => props.height && props.height};
    
    ${props => props.variant === "button" ? buttonSpinner : pageSpinner};
    
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Spinner = (props) => {

    return (
        <StySpinner {...props}>
            <section>
                <span className="loader"></span>
            </section>
        </StySpinner>
    )
};

export default Spinner;