import React, {useState} from 'react';
import styled from "styled-components";
import ScreenWidths from "../constants/ScreenWidths";
import Button from "./Button";
import {useTranslation} from "react-i18next";
import jwt from "jsonwebtoken";
import AuthPasswordPopup from "./AuthPasswordPopup";
import BankId from "../views/BankId";

const StyAuthPopup = styled.div`
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    display: ${props => props.open ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    
    & > div {
        position: relative;
        box-shadow: 0 10px 40px 2px rgba(0,0,0,0.4);
        border-radius: 8px;
        padding: 24px 32px;
        max-width: 352px;
        background-color: #FFF;
        text-align: left;
        @media (width < ${ScreenWidths.xs}){
            width: 95%;
            padding: 20px;
        }

        > p:nth-child(1){
            margin: 36px 0 16px 0;
            font-size: 24px;
            font-weight: 600;
            color: #000;
            @media (width < ${ScreenWidths.xs}) {
                font-size: 20px;
            }
        }

        > :nth-child(2){
            margin: 8px 0 28px 0;
            font-size: 16px;
            color: #4D5D70;
        }
        
        > button:first-of-type{
            padding: 8px;
        }

        > div > button {
            position: absolute;
            top: 12px;
            right: 12px;
            border: none;
            color: black;
        }
    }
`;

const AuthPopup = (props) => {
    const {t, i18n} = useTranslation()
    const [credentialsMsg, setCredentialsMsg] = useState(" ")
    const [bankIdOpen, setBankIdOpen] = useState(false)
    const [authPassOpen, setAuthPassOpen] = useState(false)

    const handleAuthComplete = (collect) => {
        const decoded = jwt.decode(collect.authResponse.accessToken);
        if (decoded != null) {
            if (((decoded.applications || []).filter(app => app.includes('Cardholder'))).length > 0) {
                localStorage.setItem('auth-token', collect.authResponse.accessToken);
                props.setAuthenticated(true);
                props.setOpen(false)
            } else {
                setCredentialsMsg(t('Invalid user or user not authorized to access the application'));
            }
        }
    }

    const handleAuthFailed = (collect) => {
        if (collect.hintCode === 'expiredTransaction') {
            setCredentialsMsg(t('The BankID app is not responding. Please check that the program is started and that you have internet access. If you don\'t have a valid BankID you can get one from your bank.'))
        } else if (collect.hintCode === 'userCancel') {
            setCredentialsMsg(t('BankID authentication cancelled.'))
        } else if (collect.hintCode === 'certificateErr') {
            setCredentialsMsg(t('The BankID you are trying to use is revoked or too old. Please use another BankID or order a new one from your internet bank.'))
        } else if (collect.hintCode === 'startFailed') {
            setCredentialsMsg(t('Failed to scan the QR code'))
        } else if (collect.hintCode === 'unauthorized') {
            setCredentialsMsg(t('bankid unauthorized'));
        } else {
            setCredentialsMsg(t('BankID authentication failed.'))
        }
    }

    return (
        <>
            <StyAuthPopup {...props}>
                <div>
                    <p>{props.title}</p>
                    <p>{props.subTitle}</p>

                    <Button onClick={()=> { props.setOpen(false); setBankIdOpen(true); }}>
                        <img src={"/images/icons/bankid-white.svg"} alt={"bank id"}/>
                        <div>{t("Log in with bankid")}</div>
                    </Button>

                    <Button variant={"outlined"} onClick={()=> {props.setOpen(false); setAuthPassOpen(true)}}>
                        <div>{t("Log in with username and password")}</div>
                    </Button>

                    <div>
                        <button onClick={() => props.setOpen(false)}>
                            <img src={"/images/icons/close.png"} alt={"close button"} height={26}/>
                        </button>
                    </div>
                </div>
            </StyAuthPopup>

            <BankId open={bankIdOpen} setOpen={setBankIdOpen} handleAuthComplete={handleAuthComplete} handleAuthFailed={handleAuthFailed} setAuthenticated={props.setAuthenticated}/>

            <AuthPasswordPopup open={authPassOpen} setOpen={setAuthPassOpen} setAuthenticated={props.setAuthenticated}/>
        </>
    )
};

export default AuthPopup;