import config from '../config'
import jwt from 'jsonwebtoken'

class JsonApi {

    constructor(baseUrl, authenticate = true) {
        this.baseUrl = baseUrl;
        this.authenticate = authenticate;
    }

    get = (url) => { return this.request(url, 'GET'); };
    getNoResponse = (url) => { return this.request(url, 'GET', null, false); };
    post = (url, requestBody) => { return this.request(url, 'POST', requestBody); };
    postNoResponse = (url, requestBody) => { return this.request(url, 'POST', requestBody, false); };
    put = (url, requestBody) => { return this.request(url, 'PUT', requestBody); };
    patch = (url, requestBody) => { return this.request(url, 'PATCH', requestBody); };
    del = (url, requestBody) => { return this.request(url, 'DELETE', requestBody, false); };

    isAuthTokenExpired = () => {
        const currentToken = localStorage.getItem('auth-token');
        const decodedToken = jwt.decode(currentToken);
        if(currentToken && decodedToken.exp && ((Date.now() / 1000) > decodedToken.exp)){
            return true;
        }
        return false;
    }

    renewToken = async () => {
        const currentToken = localStorage.getItem('auth-token');
        const decodedToken = jwt.decode(currentToken);

        if (currentToken && (decodedToken.exp - (Date.now() / 1000)) < 1500) {
            const requestConfig = {
                method: 'POST',
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${currentToken}`
                }
            };

            fetch(`${config.BACKEND_URL}/auth/token/renew`, requestConfig).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        const decoded = jwt.decode(json.accessToken);
                        if (decoded != null) {
                            localStorage.setItem('auth-token',json.accessToken);
                        }
                    });
                }
            });
        }
    }

    request = (url, requestMethod, requestBody, hasResponseBody = true) => {
        const requestConfig = {
            method: requestMethod,
            headers: { "Content-Type": "application/json" }
        };

        if (this.authenticate && localStorage.getItem('auth-token') != null) {
            requestConfig['headers']['Authorization'] = `Bearer ${localStorage.getItem('auth-token')}`

            this.renewToken();
        }

        if (requestBody) {
            requestConfig['body'] = JSON.stringify(requestBody)
        }

        return fetch(`${this.baseUrl ? this.baseUrl : ''}${url ? url : ''}`, requestConfig).then(response => {
            if (response.status === 403 && this.isAuthTokenExpired()) {
                window.location.reload();
                return;
            }

            if (!response.ok) throw response;
            else return (hasResponseBody ? response.json() : response);
        });
    }
}

export default JsonApi;