import React, {useContext} from 'react';
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import Colors from "../constants/Colors";
import {useTranslation} from "react-i18next";
import {AccountContext} from "../../components/AccountContext";

const StyDesktopNavigation = styled.div`
    margin: 36px 0 16px 0;
    ul {
        padding: 0;
        display: flex;
        list-style: none;
        width: 100%;
        justify-content: center;
        a {
            padding: 16px;
            font-size: 18px;
            font-weight: 600;
            text-decoration: none;
            color: inherit;
            opacity: 0.5;
        }
        li {
            position: relative;
            .active {
                opacity: 1;
                &:before {
                    content : "";
                    position: absolute;
                    left    : 16px;
                    bottom: -8px;
                    height  : 1px;
                    width   : calc(100% - 32px);
                    border-bottom: 2px solid ${Colors.blackLightPrimary}

                }
            }
        }
    }
    
`;

const DesktopNavigation = (props) => {
    const { t } = useTranslation();

    const { disableCalendar } = useContext(AccountContext);

    return (
        <StyDesktopNavigation {...props}>
            <ul>
                <li>
                    <NavLink to={"home"}>
                        {t("Home")}
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"transactions"}>
                        {t("Transactions")}
                    </NavLink>
                </li>
                {
                    !disableCalendar &&
                    <li>
                        <NavLink to={"topup"}>
                            {t("Top ups")}
                        </NavLink>
                    </li>
                }
                <li>
                    <NavLink to={"card"}>
                        {t("Card")}
                    </NavLink>
                </li>
            </ul>
        </StyDesktopNavigation>
    )
};

export default DesktopNavigation;