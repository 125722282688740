import React, {useContext, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useQueryRequest} from "../hooks/UseJsonApi";
import Button from "../components/Button";
import HorizontalMenu from "../components/HorizontalMenu";
import moment from "moment/moment";
import Spinner from "../components/Spinner";
import {SetNotificationValue} from '../hooks/NotificationContext';
import Popup from "../components/Popup";
import i18next from "i18next";
import SubscribeTopups from "./SubscribeTopups"

const getCutOffDate = (ts, year, month) => {
    if(ts != null && ts.lastDayOrderTopup > 0){
        if(ts.sameMonthTopup){
            return new Date(year, parseInt(month-1), ts.lastDayOrderTopup);
        }else{
            return new Date(year, parseInt(month-2), ts.lastDayOrderTopup);
        }
    }
    return null;
}

const checkIfPossibleToChooseTopup = (tr, ts, key) => {
    return !ts.disableCalendar && canChooseTopup(tr, ts, key);
}

const getMonthNumeral = (monthString) => {
    switch(monthString) {
        case 'january': return 1;
        case 'february': return 2;
        case 'march': return 3;
        case 'april':return 4;
        case 'may': return 5;
        case 'june': return 6;
        case 'july': return 7;
        case 'august': return 8;
        case 'september': return 9;
        case 'october': return 10;
        case 'november': return 11;
        case 'december': return 12;
    }
}

const canChooseTopup = (item, ts, key) => {
    if(item != null && (item[key] === 'ORDERED' || item[key] === 'NOT_ORDERED') && item.account?.cardHolder?.status !== 'INACTIVE' && item.account?.cardHolder?.status !== 'DEACTIVATED'){
        var cutOffDate = getCutOffDate(ts, item.year, getMonthNumeral(key));
        return cutOffDate != null && ((new Date().setHours(0,0,0,0)) <= cutOffDate.getTime());
    }

    return false;
}

const shouldAddButtonOrderNow = (tr, ts, amountTopupLeft, key) => {
    if ((tr[key] === 'NOT_ORDERED')  && amountTopupLeft > 0){
        return checkIfPossibleToChooseTopup(tr, ts, key);
    }

    return false;
}

const shouldAddButtonCancel = (tr, ts, key) => {
    if (tr[key] === 'ORDERED') {
        return checkIfPossibleToChooseTopup(tr, ts, key);
    }
    return false;
}

const hasInvoice = (item, key) => {
    switch (getMonthNumeral(key)) {
        case 1: return item.hasInvoiceJan;
        case 2: return item.hasInvoiceFeb;
        case 3: return item.hasInvoiceMar;
        case 4: return item.hasInvoiceApr;
        case 5: return item.hasInvoiceMay;
        case 6: return item.hasInvoiceJun;
        case 7: return item.hasInvoiceJul;
        case 8: return item.hasInvoiceAug;
        case 9: return item.hasInvoiceSep;
        case 10:return item.hasInvoiceOct;
        case 11:return item.hasInvoiceNov;
        case 12:return item.hasInvoiceDec;
    }
    return false;
}

const returnMessage = (item, ts, key) => {
    const topupDateString = item.year + '/' + new String(getMonthNumeral(key)).padStart(2, '0') + '/' + new String(ts.dayTopup).padStart(2, '0');
    const topupDate = moment(topupDateString, 'YYYY/MM/DD');
    const t = i18next.t;

    if (item[key] === 'BLOCKED') {
        return i18next.t('Top-up is blocked for this month');
    } else if (item[key] === 'NOT_ORDERED') {
        if (hasInvoice(item, key)) {
            if (topupDate.isBefore(new Date())) {
                return i18next.t('Top-up was received');
            } else {
                return i18next.t('Top-up will be received') + topupDateString;
            }
        } else {
            if (topupDate.isBefore(new Date())) {
                return i18next.t('Top-up was not received');
            } else {
                return i18next.t('Top-up will not be received');
            }
        }
    } else if (item[key] === 'ORDERED') {
        if (hasInvoice(item, key)) {
            if (topupDate.isBefore(new Date())) {
                return i18next.t('Top-up was received');
            } else {
                return i18next.t('Top-up will be received') + topupDateString;
            }
        } else {
            if (topupDate.isBefore(new Date())) {
                return i18next.t('Top-up was not received');
            } else {
                return i18next.t('Top-up was ordered') + topupDateString;
            }
        }
    } else if (item[key] === 'CANCELED') {
        return i18next.t('Top-up was canceled');
    }

    return '';
}

const StyTopup = styled.div`
    
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 8px;
    
    > :nth-child(1){
        max-width: 488px;
        >:nth-child(2){
            font-size: 20px;
            font-weight: 600;
            margin: 4px 0;
            min-height: 24px;
        };
        >:nth-child(3), >:nth-child(4){
            font-size: 14px;
            margin: 12px 0 16px 0;
        }
    }
    
    .calendar-years{
        padding-top: 8px;
        max-width: 488px;
    }
    
    .calendar {
        max-width: 488px;
        width: 100%;
        > div {
            margin: 8px 0;
            border: 2px solid #D1D5D7;
            padding: 20px;
            border-radius: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p:nth-child(1){
                color: #6C10B9;
                font-weight: 600;
            };
            p:nth-child(2){
                padding-top: 4px;
                color: #4D5D70;
                font-size: 12px
            }
            button {
                margin: 0
            }
        }
    }
`;

const Topup = React.memo((props) => {

    const {t, i18n} = useTranslation()
    const currentDate =  new Date()
    const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear())
    const [years, setYears] = useState([currentDate.getFullYear(), currentDate.getFullYear() +1, currentDate.getFullYear() +2])
    const [monthOrdered, setMonthOrdered] = useState()
    const [monthCancelled, setMonthCancelled] = useState()
    const [calendarTrigger, setCalendarTrigger] = useState(0)
    const listLabelMonths = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
    const {setNotification} = useContext(SetNotificationValue);
    const [monthSelected, setMonthSelected] = useState(null)

    const nextTopup = useQueryRequest({
        url:"/cardholder/dashboard/topup/next",
        queryKey: ["nextTopup", monthOrdered, monthCancelled]
    })

    const calendar = useQueryRequest({
        queryKey: ["calendar", selectedYear, calendarTrigger],
        url: "/cardholder/topups/calendar/" + selectedYear
    });

    const orderTopup = useQueryRequest({
        type: "post",
        queryKey: ["orderTopup", monthOrdered],
        url: "/cardholder/topups/" +selectedYear+ "/" + monthOrdered + "/order",
        enabled: !!monthOrdered,
        onSuccess: () => {
            setCalendarTrigger(calendarTrigger + 1);
            setMonthOrdered(null);
            setNotification( { type: "success", message: t(`Top-up order updated successfully`)})
        }
    });

    const cancelTopup = useQueryRequest({
        type: "post",
        queryKey: ["cancelTopup", monthCancelled],
        url: "/cardholder/topups/" +selectedYear+ "/" + monthCancelled + "/cancel",
        enabled: !!monthCancelled,
        onSuccess: () => {
            setCalendarTrigger(calendarTrigger + 1);
            setMonthCancelled(null);
            setNotification( { type: "success", message: t(`Top-up order updated successfully`)})
        }
    });

    const strTopupDate = () => {
        if (nextTopup.data?.nextTopup.substring(8) === "01") {
            return nextTopup.data?.nextTopup.substring(8) + t("st")
        } else {
            return nextTopup.data?.nextTopup.substring(8) + t("th")
        }
    }

    return (
        <StyTopup {...props}>
            <div>
                <p>{t("Next top up")}</p>
                <p>{ nextTopup.data?.nextTopup && strTopupDate()  + " " + t(listLabelMonths[nextTopup.data?.nextTopup.substring(5,7).replace(/\b0+/g,"") - 1])}</p>
                <p>{t("The number of months in advance you can manage your top-ups is decided by your employer.")}</p>
                <p>{t("You need to order your top-up by the") + " " + ( calendar.data ? calendar.data?.topupSettings.lastDayOrderTopup : " ") + t("th") + " " +t("each month, to receive your coming top-up.")}</p>
            </div>

            { calendar.data?.topupSettings?.enableSubscribe &&
                <SubscribeTopups subcribedChanged={() => setCalendarTrigger(calendarTrigger + 1)}/>
            }

            <HorizontalMenu arr={years} selected={selectedYear} onSelect={(item) => setSelectedYear(item)} className={"calendar-years"}/>

            { calendar.isLoading
                ?
                <Spinner height={"600px"}/>
                :
                <div className={"calendar"}>
                    { calendar.data && listLabelMonths?.map( monthLabel => {
                        return (
                            <div key={monthLabel}>
                                <div>
                                    <p>{t(monthLabel)}</p>
                                    <p>{returnMessage(calendar.data, calendar.data.topupSettings, monthLabel)}</p>
                                </div>
                                <div>
                                    {
                                        shouldAddButtonOrderNow(calendar.data, calendar.data.topupSettings, calendar.data.topupSettings.monthsReceiveTopup - calendar.data.confirmedCount, monthLabel) &&
                                        <Button
                                            variant={"outlined"} size={"small"}
                                            disabled={calendar.data.topupSettings?.branch?.clientPackage?.client?.status === "CLOSED" || calendar.data.topupSettings?.branch?.status === "CLOSED"}
                                            onClick={() => setMonthOrdered(listLabelMonths.indexOf(monthLabel) + 1)}>
                                            {t("Order now")}
                                        </Button>
                                    }
                                    {
                                        shouldAddButtonCancel(calendar.data, calendar.data.topupSettings, monthLabel) &&
                                        <Button
                                            variant={"outlined"} size={"small"}
                                            disabled={calendar.data.topupSettings?.branch?.clientPackage?.client?.status === "CLOSED" || calendar.data.topupSettings?.branch?.status === "CLOSED"}
                                            onClick={ ()=> setMonthSelected(monthLabel)}
                                        >
                                            {t("Cancel")}
                                        </Button>

                                    }
                                </div>
                            </div>
                        )})
                    }
                </div>
            }
            <Popup
                open={monthSelected}
                title={t("Cancel top-up for") +" "+ t(monthSelected)}

                rightBtLabel={t("Yes, cancel top up")}
                rightBtAction={() => { setMonthCancelled(listLabelMonths.indexOf(monthSelected) + 1) ; setMonthSelected(null) }}

                leftBtLabel={t("No, exit")}
                leftBtAction={() => { setMonthSelected(null) }}

                onClose={() => setMonthSelected(null)}
            >
                {t("Are you sure you want to cancel this top-up?")}
            </Popup>

        </StyTopup>
    )
});

export default Topup;