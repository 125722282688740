import React, {useContext} from "react";
import {matchRoutes, Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import ScrollTop from "../../components/ScrollTop";

import Footer from "./Footer";
import MobileNavigation from "./MobileNavigation";
import styled from "styled-components";
import ScreenWidths from "../constants/ScreenWidths";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import UseMediaQuery from "../hooks/UseMediaQuery";
import DesktopNavigation from "./DesktopNavigation";
import UseAuth from "../hooks/UseAuth";
import Home from "./Home";
import Transactions from "./Transactions";
import Topup from "./Topup";
import Support from "./Support";
import Login from "./Login";
import LgBankId from "./LgBankId";
import LgEmail from "./LgEmail";
import LgRecover from "./LgRecover";
import Card from "./Card";
import Settings from "./Settings";
import Details from "./Details";
import Service from "./Service";
import Limits from "./Limits";
import {useQueryRequest} from "../hooks/UseJsonApi";
import {useTranslation} from "react-i18next";
import {AccountContext} from "../../components/AccountContext";
import EditPhone from "./EditPhone";
import EditEmail from "./EditEmail";
import ReplacementCardForm from "./ReplacementCardForm";
import VerifyCode from "./VerifyCode";
import Cookies from "./Cookies";
import NotFound from "./NotFound";
import LgChangePassword from "./LgChangePassword";


const StySection = styled.div`
    .authorized {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        justify-content: center;
    }
    .body-content {
        width: 100%;
        overflow: hidden;
        margin: 24px auto 36px;
        max-width: 1018px;
        @media (width < ${ScreenWidths.xs}){
            max-width: 370px;
        }
        flex-grow: 1;
        padding: 0 16px;
        @media (width < ${ScreenWidths.xs}){
            margin-top: 96px;
            padding: 0 4px;
        }
    }
`;

const AppBody = (props) => {
    const location = useLocation()
    const isSmallDevice = UseMediaQuery(`(width < ${ScreenWidths.xs})`);
    const {isLoggedIn} = UseAuth()
    const {i18n} = useTranslation()
    const { account, setAccount, setCard, disableCalendar, setDisableCalendar } = useContext(AccountContext);

    const showNavigation = matchRoutes([
        {path: "support"},
        {path: "settings/*"},
        {path: "/edit-phone"},
        {path: "/edit-email"},
        {path: "/verify-email"},
        {path: "/replacement-card"}
    ], location)

    const runActiveCardAccount = matchRoutes([
        {path: "home"},
        {path: "transactions"},
        {path: "card"}
    ], location)

    const setDefaultLanguage = useQueryRequest({
        url:"/cardholder/info/",
        queryKey: ["AppBody", {once: true}],
        enabled: isLoggedIn(),
        onSuccess: (data) => { data.preferences?.language && i18n.changeLanguage(data.preferences?.language) }
    })

    const setActiveAccount = useQueryRequest({
        url:"/cardholder/accounts/v2",
        enabled: !account && !!runActiveCardAccount,
        queryKey: ["AppBody"],
        onSuccess: ( data ) => {
                const dataAccounts = data?.filter(it => it.topupLevel?.branchId !== 9).sort((a, b) => b.id - a.id);
                setAccount(dataAccounts?.find(it => it.status === 'ACTIVE') ? dataAccounts?.find(it => it.status === 'ACTIVE') : dataAccounts[0] )
        }
    })


    const calendar = useQueryRequest({
        queryKey: ["calendar", {once: true}],
        url: "/cardholder/topups/calendar/" + new Date().getFullYear(),
        enabled: isLoggedIn(),
        onSuccess: data => {
            setDisableCalendar( data?.topupSettings ? data?.topupSettings?.disableCalendar : true)
        }
    });


    const setActiveCard = useQueryRequest({
        queryKey: ["AppBody", account],
        url:`/cardholder/accounts/v2/${account?.id}/cards`,
        enabled: !!account && !!runActiveCardAccount,
        onSuccess: ( data ) => {
            setCard(data?.find(it => it?.status === 'ACTIVE') ? data?.find(it => it?.status === 'ACTIVE') : data[0]);
        }
    })

    const IsAuthorized = () => {
        return (
            isLoggedIn() === true
                ?
                <section className={"authorized"}>
                    { isSmallDevice
                            ? <> <MobileMenu/> { !showNavigation && <MobileNavigation/>}  </>
                            : <> <DesktopMenu/> { !showNavigation && <DesktopNavigation/>} </>
                    }
                    <Cookies/>
                    <section className={"body-content"}>
                        <Outlet/>
                    </section>
                    <Footer/>
                </section>
                :
                <Navigate to="/login/bankid" state={{ from: location }} replace />
        )
    };

    return (
        <StySection {...props}>
            <ScrollTop/>
            <Routes>
                <Route path="login" element={<Login/>}>
                    <Route index element={<Navigate replace to="/login/bankid" />}/>
                    <Route path="bankid" element={<LgBankId/>}/>
                    <Route path='email' element={<LgEmail/>}/>
                    <Route path='email-recover' element={<LgRecover/>}/>
                    <Route path='reset-password/:token' element={<LgChangePassword />} />
                </Route>

                <Route element={<IsAuthorized/>}>
                    <Route path='topup-calendar' element={<Navigate to="/topup" replace />} />
                    <Route path='/home' element={<Home/>}/>
                    <Route path='/settings' element={<Settings/>}/>
                    <Route path='/card' element={<Card/>}/>
                    <Route path='/support' element={<Support/>}/>
                    <Route path="/transactions" element={<Transactions/>}/>
                    { !disableCalendar && <Route path='/topup' element={<Topup/>}/>}
                    <Route path='/edit-phone' element={<EditPhone/>}/>
                    <Route path='/edit-email' element={<EditEmail/>}/>
                    <Route path='/verification-code' element={<VerifyCode/>}/>
                    <Route path='/replacement-card' element={<ReplacementCardForm/>}/>
                    <Route path='*' element={<NotFound/>}/>

                    <Route path="settings" element={<Settings/>} >
                        <Route index element={<Navigate replace to="/settings/user" />} />
                        <Route path="user" element={<Details/>}/>
                        <Route path='notifications' element={<Service/>}/>
                        <Route path='limits' element={<Limits/>}/>
                    </Route>

                </Route>
            </Routes>
        </StySection>
    );
};

export default AppBody;