import React, {useContext} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import ScreenWidths from "../constants/ScreenWidths";
import {useLocation, useNavigate} from "react-router-dom"
import {useForm} from "react-hook-form";
import {SetNotificationValue} from "../hooks/NotificationContext";
import {useQueryRequest} from "../hooks/UseJsonApi";
import InputWrapper from "../components/InputWrapper";
import Button from "../components/Button";


const StyVerifyCode = styled.div`
    margin: 0 auto;
    @media (width < ${ScreenWidths.xs}){
        margin: 0 8px;
    }
    max-width: 520px;

    > :nth-child(1) {
        > h5 {
            font-weight: 600;
            margin: 16px 0 12px 0;
            font-size: 18px;
        }
        
        > span {
            display: block;
            font-weight: 600;
            margin: 4px 0;
            font-size: 18px;
        }

        > button {
            display: flex;
            border: 2px solid #D1D5D7;
            padding: 8px;
            border-radius: 50%;
            img {
                height: 14px;
                width: 14px;
            }
        }
    }
    
    form {
        margin-top: 12px;
        > p {
            margin: 12px 0 24px 0
        }
    }
`;

const EditEmail = (props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors, isValid, isSubmitted, isDirty}, reset, getValues, setValue, setError } = useForm({ mode: "all"});
    const {setNotification} = useContext(SetNotificationValue);
    const location = useLocation();

    const requestVerificationCode = useQueryRequest({
        url:"/cardholder/contact-info/"+ location?.state?.contactInformationId +"/verification?code="+ getValues("verificationCode"),
        noRes: true,
        type: "POST",
        queryKey: ["VerifyCode"],
        enabled: isSubmitted,
        onSuccess: (data) => {
            if (data?.status === 500) {
                reset()
                setError("verificationCode", {type: "custom", message: t("The code you entered is incorrect")});
            } else {
                setNotification({
                    type: 'success',
                    message: location?.state?.contactInformationType === 1 ? t("Email validated successfully") : t("Phone number validated successfully")
                });
                navigate("/settings")
            }
        },
        onError: (error) => {
            reset()
        }
    })

    return (
        <StyVerifyCode {...props}>
            <div>
                <button onClick={() => navigate(-1)}>
                    <img src={"/images/icons/back-arrow.svg"} alt={"go to page"}/>
                </button>

                <h5>{t("Enter verification code")}</h5>

                <p>{location?.state?.contactInformationType === 1 ? t("We will send you a verification code to your e-mail") : t("We will send you a verification code via sms to your phone")}</p>
                <span>{location?.state?.value}</span>
                <p>{t("Please note that the code is only valid for 30 minutes.")}</p>
            </div>

            <form onSubmit={handleSubmit( () => {})} autoComplete={"off"}>
                <InputWrapper error={errors.verificationCode} label={t("Verification code")} value={watch("verificationCode")}>
                    <input
                        {...register(
                            "verificationCode",
                            {
                                required: t("This field is required"),
                                pattern: { value: /^[0-9]+$/i, message: t("Value must be a valid number")}
                            })}
                    />
                </InputWrapper>

                <Button
                    type={"submit"}
                    disabled={!isValid}
                    loading={requestVerificationCode.isFetching || requestVerificationCode.isFetching}
                >
                    <div>{t("confirm")}</div>
                </Button>
            </form>

        </StyVerifyCode>
    )
};

export default EditEmail;