import React from 'react';
import styled from "styled-components";
import ScreenWidths from "../constants/ScreenWidths";

const StyTooltip = styled.div`
    
    position: relative;
    display: flex;
    align-content: center;
    &:hover span {
        @media (width > ${ScreenWidths.xs}){
            visibility: visible;
        }
    }
    
    span {
        width: ${props => props.width ? props.width : "120px"};
        visibility: hidden;
        background-color: #30363F;
        color: #fff;
        border-radius: 6px;
        padding: 14px;
        z-index: 1;
        position: absolute;
        left: calc(100% + 4px);
        top: -8px;
        &:before {
            content: "";
            position: absolute;
            right: 100%;
            border-width: 5px;
            border-style: solid;
            border-color: transparent black transparent transparent;
        }
    }
        
`;

const Tooltip = (props) => {
    return (
        <StyTooltip {...props}>
            {props.children}
            <span>
                {props.label}
            </span>
        </StyTooltip>
    )
};

export default Tooltip;