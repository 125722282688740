import React, {useState} from 'react';
import styled, {css} from "styled-components";
import InputErrorMsg from "./InputErrorMsg";


const StySelectWrapper = styled.div`

    min-height: ${props => props.slim ? "40px" : "120px"};

    .select-wrapper-container {
        
        select {
            appearance: ${props => props?.inputGroup && "none"};
            padding: ${props => props?.inputGroup ? "24px 24px 24px 18px" : "8px 24px 8px 8px"};
            border-radius: ${props => props?.inputGroup && "10px"} ;
            border: ${props => props.error ? "2px solid #A80000" : "1px solid #D1D5D7"} ;
            width: ${props => props?.inputGroup && "100%"};
            color: ${props => props?.error && "#A80000"};
            background-color: transparent;
        
            option {
                color: #000;
                background-color: #fff;
            }
        }


        ${props => props.inputGroup && css`
            > div:nth-child(1) {
                position: relative;
                &::after {
                    content: "▼";
                    font-size: 1rem;
                    top: 26px;
                    right: 12px;
                    position: absolute;
                }
            }`
        }

        > div:nth-child(2) {
            margin-top: 8px;
        }
        
    }
`;

const InputWrapper = (props) => {
    const { viewPassword, ...rest } = props;
    const [showPassword, setShowPassword] = useState(false)

    return (
        <StySelectWrapper {...rest}>
            <div className={"select-wrapper-container"}>
                <div>
                    {props.children}
                    <p>{ (props.infoMsg && (props.value?.length === 0 || props.value === undefined) && !props.error ) && props.infoMsg}</p>
                </div>
                <div>
                    {props.error && <InputErrorMsg label={props.error.message}/>}
                </div>
            </div>
        </StySelectWrapper>
    )
};

export default InputWrapper;