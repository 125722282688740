import React, { createContext, useState } from "react";
import Notification from "../components/Notification";

export const GetNotificationValue = createContext(null);
export const SetNotificationValue = createContext(null);


export const NotificationProvider2 = ({ children }) => {
    const [notification, setNotification] = useState( null);

    return (
        <SetNotificationValue.Provider value={{setNotification}}>
            {children}
            <GetNotificationValue.Provider value={{notification}}>
                { notification &&
                    <Notification values={{type: notification.type, message: notification.message, timeOut: notification.timeout, title: notification.title}}/>
                }
            </GetNotificationValue.Provider>
        </SetNotificationValue.Provider>
    );
};