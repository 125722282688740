import React, {useContext, useState} from 'react';
import styled from "styled-components";
import CardPreview from "./CardPreview";
import {useQueryRequest} from "../hooks/UseJsonApi";
import HomeBanner from "../components/HomeBanner";
import Articles from "./Articles";
import HomeTransactions from "./HomeTransactions";
import ScreenWidths from "../constants/ScreenWidths";
import {AccountContext} from "../../components/AccountContext";
import UnblockPin from "./UnblockPin";
import Popup from "../components/Popup";
import {useTranslation} from "react-i18next";
import Button from "../components/Button";
import {useNavigate} from "react-router-dom";

const StyHome = styled.div`
    > :nth-child(3) {
        @media (width > ${ScreenWidths.xs}) {
            display: flex;
            align-items: flex-start;
            > :nth-child(1) {
                flex-basis: 33%;
            }

            > :nth-child(2) {
                flex-basis: 66%;
            }
        }
    }
    
    .updateContactInfo {
        font-weight: 600;
        margin: 16px 0
    }
`;

const Home = (props) => {

    const { card } = useContext(AccountContext);
    const {t, i18n} = useTranslation();
    const [updateContactInfoPopup, setUpdateContactInfoPopup] = useState(true);
    const navigate = useNavigate();
    const [triggerClearContactInfo, setTriggerClearContactInfo] = useState(false)
    const [triggerSetContactInfo, setTriggerSetContactInfo] = useState(false)


    const notifications = useQueryRequest({
        url:"/cardholder/notifications/active/cardholder",
        queryKey: ["Home"],
    })

    const pinStatus = useQueryRequest({
        url:"/cardholder/cards/v2/" + card?.referenceCode + "/card-pin-status",
        queryKey: ["Home"],
        enabled: !!card
    })

    const getChInformation = useQueryRequest({
        url:"/cardholder/info/",
        queryKey: ["Home"],
    })

    const clearContactInfo = useQueryRequest({
        type: "POST",
        queryKey: ["Home-ClearContactInfo"],
        enabled: triggerClearContactInfo,
        url:"/cardholder/contactInfo",
        body: {
            email: "temp@temp.com",
            phone: "00000000"
        },
        onSuccess: (data) => {
            setTriggerSetContactInfo(true)
            setTriggerClearContactInfo(false)
        }
    })

    const setNewContactInfo = useQueryRequest({
        type: "POST",
        queryKey: ["Home"],
        enabled: triggerSetContactInfo,
        url:"/cardholder/contactInfo",
        body: {
            email: getChInformation.data?.personalInformation?.contactInfos.find(a => a.contactInformationType.descriptionEnglish === "Email")?.description,
            phone: getChInformation.data?.personalInformation?.contactInfos.find(a => a.contactInformationType.descriptionEnglish === "Phone")?.description
        },
        onSuccess: (data) => {
            setTriggerSetContactInfo(false)
        }
    })

    return (
        <StyHome {...props}>
            <div>
                { Boolean(pinStatus.data?.counter && pinStatus.data?.max && pinStatus.data?.counter >= pinStatus.data?.max) &&
                    <UnblockPin/>
                }
            </div>

            <div>
                { notifications.data &&
                    <HomeBanner
                        variant={"info"}
                        title={i18n.language === "en" ? notifications.data?.summaryEnglish : notifications.data?.summarySwedish}
                        content={i18n.language === "en" ? notifications.data?.textEnglish : notifications.data?.textSwedish}
                        id={notifications.data?.id}
                    />
                }
            </div>

            <div>
                <CardPreview/>
                <HomeTransactions/>
            </div>

            { getChInformation.data?.personalInformation?.contactInfos.some( item => new Date(item.modifiedDate) < new Date().setMonth(new Date().getMonth() - 6)) &&

                <Popup open={updateContactInfoPopup} title={t("Check your contact details")}>
                    <p>
                        {t("Are your email and phone number still the same?")}
                    </p>
                    <div className={"updateContactInfo"}>
                        <p>
                            {getChInformation.data?.personalInformation?.contactInfos.find(a => a.contactInformationType.descriptionEnglish === "Email")?.description}
                        </p>
                        <p>
                            {getChInformation.data?.personalInformation?.contactInfos.find(a => a.contactInformationType.descriptionEnglish === "Phone")?.description}
                        </p>
                    </div>

                    <Button onClick={() => navigate("/settings/details")} variant={"outlined"}>{t("No, I want to update my contact details now")}</Button>
                    <Button onClick={() => { setUpdateContactInfoPopup(false); setTriggerClearContactInfo(true)}}>{t("Yes, my contact details are correct")}</Button>
                </Popup>
            }


            <Articles />

        </StyHome>
    )
};

export default Home;