import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {SetNotificationValue} from "../hooks/NotificationContext";
import JsonApi from "../../components/JsonApi";
import ScreenWidths from "../constants/ScreenWidths";
import config from "../../config";
import InputWrapper from "../components/InputWrapper";
import {useForm} from "react-hook-form";
import Button from "../components/Button";
import Regex from "../constants/RegexStr";
import RegexStr from "../constants/RegexStr";

const StyLgChangePassword = styled.div`
    > :nth-child(1){
        font-size: 24px;
        margin-bottom: 32px;
        font-weight: 600;
        @media (width < ${ScreenWidths.xs}) {
            font-size: 20px;
        }
    }

    form {
        > :nth-child(2) {
            margin-bottom: 24px
        }
    }
`;

const LgChangePassword = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const {setNotification} = useContext(SetNotificationValue);
    const [ip, setIp] = useState();
    const [token, setToken] = useState();
    const api = new JsonApi(config.BACKEND_URL, false);
    const { register, handleSubmit, watch, formState: { errors }, reset, getValues } = useForm({mode: "all"});

    const [oldPassShow, setOldPassShow] = useState(false)
    const [newPassShow, setNewPassShow] = useState(false)
    const [repeatPassShow, setRepeatPassShow] = useState(false)

    useEffect(() => {
        api.get(`/auth/password/reset/${params.token}`).then(json => {
            if (json.used) {
                localStorage.removeItem('auth-token');
                setNotification({type: "error", message: t("This link to set password was already used or has expired.")});
                navigate("/login")
            } else {
                fetch(`https://geolocation-db.com/json/`)
                    .then(res => res.json())
                    .then(json => setIp(json?.IPv4))
                setToken(json.token);
            }
        }).catch(error => {
            localStorage.removeItem('auth-token');
            setNotification({type: "error", message: t("This link to set password was already used or has expired.")});
            navigate("/login")
        })
    }, []);

    const onSubmit = data => {
        api.post('/auth/password/change', { token: token, newPassword: data.lgNewPassword,  ipAddress: ip}).then(json => {
            localStorage.setItem('auth-token', json.accessToken);
            navigate("/home")
            setNotification({type: "success", message: t("Password changed successfully")})
        }).catch(error => {
            localStorage.removeItem('auth-token');
            setNotification({type: "error", message: t("This link to set password was already used or has expired.")})
            navigate("/login")
        });
    };

    return (
        <StyLgChangePassword {...props}>
            <p>{t("Change password")}</p>

            <form onSubmit={handleSubmit(onSubmit)}>
                <InputWrapper
                    error={errors.lgNewPassword} label={t("New password")} value={watch("lgNewPassword")} slim
                    viewPassword={() => setNewPassShow(!newPassShow)}
                    infoMsg={t("The password must contain at least 8 characters, 1 uppercase character, 1 lowercase character, and 1 special character.")}>
                    <input
                        autoComplete={false}
                        type={ newPassShow ? "text" : "password"}
                            {...register("lgNewPassword", {
                                required: "This field is required",
                                validate: {
                                    blankSpaces: (value) => RegexStr.hasBlankSpace.test(value) || "Blank spaces are not allowed",
                                    isUppercase: (value) => RegexStr.isUppercase.test(value) ||  t("Password does not meet requirements") + ": " + t("must contain 1 uppercase character"),
                                    isLowercase: (value) => RegexStr.isLowercase.test(value) ||  t("Password does not meet requirements") + ": " + t("must contain 1 lowercase character"),
                                    isSpecial: (value) => RegexStr.isSpecial.test(value) ||  t("Password does not meet requirements") + ": " + t("must contain 1 special character"),
                                    minLength: (value) => RegexStr.minLength.test(value) || t("Password does not meet requirements") + ": " + t("must contain minimum 8 characters"),
                                }
                            })
                        }
                    />
                </InputWrapper>

                <InputWrapper error={errors.lgRepeatPassword} label={t("Repeat new password")} value={watch("lgRepeatPassword")} slim viewPassword={() => setRepeatPassShow(!repeatPassShow)}>
                    <input
                        autoComplete={false}
                        type={ repeatPassShow ? "text" : "password"}
                        {...register("lgRepeatPassword", {required: "This field is required", validate: value => value === getValues("lgNewPassword") || "Passwords do not match" })}
                    />
                </InputWrapper>

                <Button type={"submit"} >
                    <div>{t("Reset password")}</div>
                </Button>
            </form>
        </StyLgChangePassword>

    )
};

export default LgChangePassword;