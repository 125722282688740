import React, {useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import ScreenWidths from "../constants/ScreenWidths";
import {useNavigate} from "react-router-dom"
import {useForm} from "react-hook-form";
import {useQueryRequest} from "../hooks/UseJsonApi";
import InputWrapper from "../components/InputWrapper";
import Button from "../components/Button";


const StyEditEmail = styled.div`
    margin: 0 auto;
    @media (width < ${ScreenWidths.xs}){
        margin: 0 8px;
    }
    max-width: 520px;

    > :nth-child(1) {
        > p {
            font-weight: 600;
            margin: 16px 0 4px 0;
            font-size: 18px;
        }

        > button {
            display: flex;
            border: 2px solid #D1D5D7;
            padding: 8px;
            border-radius: 50%;
            img {
                height: 14px;
                width: 14px;
            }
        }
    }
    
    form {
        > p {
            margin: 12px 0 24px 0
        }
    }
`;

const EditEmail = (props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors, isValid, isSubmitted, isDirty}, reset, getValues, setValue } = useForm({ mode: "all"});
    const [currentEmail, setCurrentEmail] = useState();

    const getEmail = useQueryRequest({
        queryKey: ["EditEmail"],
        enabled: !isDirty,
        url:"/cardholder/info",
        onSuccess: (data) => {
            setValue("email", data?.personalInformation?.contactInfos.find(a => a.contactInformationType.id === 1)?.description )
            setCurrentEmail(data?.personalInformation?.contactInfos.find(a => a.contactInformationType.id === 1))
        }
    })

    const requestVerificationCode = useQueryRequest({
        url:"/cardholder/contact-info/verification-request",
        type: "POST",
        queryKey: ["EditEmail"],
        enabled: isSubmitted,
        body: {
            "contactInformationId": currentEmail?.id || null,
            "contactInformationTypeId": 1,
            "newValue": getValues("email")
            //"personalInformationId": getEmail.data?.personalInformation.id
        },
        onSuccess: (data) => {
            navigate("/verification-code", {state: {contactInformationId: data?.contactInformationId, value: getValues("email"), contactInformationType: 1}})
            reset();
        },
        onError: () => {
            reset()
        }
    })

    return (
        <StyEditEmail {...props}>
            <div>
                <button onClick={() => navigate(-1)}>
                    <img src={"/images/icons/back-arrow.svg"} alt={"go to page"}/>
                </button>

                <p>{t("Edit email")}</p>
            </div>

            <form onSubmit={handleSubmit( () => {})} autoComplete={"off"}>
                <InputWrapper error={errors.email} label={t("Email")} value={watch("email")} slim={"true"}>
                    <input
                        {...register("email", {
                            required: t("This field is required"),
                            pattern: { value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i, message: t("Value must be a valid email address")},
                            validate: {
                                wasChanged: (value) => value !== currentEmail?.description ||  t("Change your email")
                            }
                        } )}
                    />
                </InputWrapper>

                <p>{t("We will send you a code to verify the change")}</p>

                <Button
                    type={"submit"}
                    disabled={!isValid}
                    loading={requestVerificationCode.isFetching || getEmail.isFetching}
                >
                    <div>{t("Send verification code")}</div>
                </Button>
            </form>

        </StyEditEmail>
    )
};

export default EditEmail;