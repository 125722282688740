import React, {useState} from 'react';
import styled from "styled-components";
import ScreenWidths from "../constants/ScreenWidths";
import Button from "./Button";
import {useTranslation} from "react-i18next";
import jwt from "jsonwebtoken";
import {useLocation, useNavigate} from "react-router-dom";
import InputWrapper from "./InputWrapper";
import InputErrorMsg from "./InputErrorMsg";
import GhostButton from "./GhostButton";
import JsonApi from "../../components/JsonApi";
import config from "../../static/config";
import {useForm} from "react-hook-form";

const StyAuthPasswordPopup = styled.div`
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    display: ${props => props.open ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    
    & > div {
        position: relative;
        box-shadow: 0 10px 40px 2px rgba(0,0,0,0.4);
        border-radius: 8px;
        padding: 24px 32px;
        max-width: 400px;
        background-color: #FFF;
        text-align: left;
        @media (width < ${ScreenWidths.xs}){
            max-width: 360px;
            width: 95%;
            padding: 20px;
        }

        > p{
            margin: 24px 0 16px 0;
            font-size: 24px;
            font-weight: 600;
            color: #000;
            @media (width < ${ScreenWidths.xs}) {
                font-size: 20px;
            }
        }

        > form {
            > :nth-child(3) {
                margin: 8px 0 20px 0;
            }
        }

        > div > button {
            position: absolute;
            top: 12px;
            right: 12px;
            border: none;
            color: black;
        }
    }

    
`;

const AuthPasswordPopup = (props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { register, handleSubmit, watch, formState: { errors, isValid }, reset } = useForm({mode: "onBlur"});
    const [credentialsMsg, setCredentialsMsg] = useState("")
    const [mfaRequired, setMfaRequired] = useState();
    const [loadingQuery, setLoadingQuery] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const onSubmit = data => {
        setLoadingQuery(true)
        new JsonApi(config.BACKEND_URL, false).post('/auth/auth/database', { username: data.username, password: data.password, application : "CARDHOLDER_PORTAL"})
            .then(json => {
                setLoadingQuery(false)
                const decoded = jwt.decode(json.accessToken);
                if (decoded != null) {
                    if (((decoded.applications || []).filter(app => app.includes('Cardholder'))).length > 0) {
                        localStorage.setItem('auth-token', json.accessToken);
                        props.setAuthenticated(true);
                        props.setOpen(false)
                    } else {
                        setCredentialsMsg(t('Invalid user or user not authorized to access the application'));
                    }
                }
                reset();
            }).catch(error => {
            if (error.json) {
                setLoadingQuery(false)
                error.json().then(json => {
                    const message = json['error_messages'] ? json['error_messages'].join('\n') : json['error_message'];
                    if ('MFA is required' === message) {
                        setMfaRequired(true);
                    } else {
                        message ? setCredentialsMsg(t(message)) : setCredentialsMsg(t("Invalid username or password"));
                    }
                });
            } else {
                setLoadingQuery(false)
                console.log(t('Could not fetch data from the server'));
            }
            reset();
        });
    };

    return (
        <StyAuthPasswordPopup {...props}>
            <div>
                <p>{t("Log in with username")}</p>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputWrapper error={errors.username} label={t("Username")} value={watch("username")}>
                        <input
                            {...register("username", {required: t("This field is required")})}
                        />
                    </InputWrapper>

                    <InputWrapper error={errors.password} label={t("Password")} value={watch("password")} viewPassword={() => setShowPassword(!showPassword)}>
                        <input
                            className={"eye"}
                            type={ showPassword ? "text" : "password"}
                            {...register("password", {required: t("This field is required")})}
                        />
                    </InputWrapper>

                    { credentialsMsg && <InputErrorMsg label={credentialsMsg} justify={"center"}/>}

                    <Button type={"submit"} onClick={() => { setCredentialsMsg(null)}} loading={loadingQuery}>
                        <div>{t("Log in")}</div>
                    </Button>

                    <GhostButton label={t("Forgot your password?")} onClick={() => navigate("/login/email-recover")}/>

                </form>

                <div>
                    <button onClick={() => props.setOpen(false)}>
                        <img src={"/images/icons/close.png"} alt={"close button"} height={26}/>
                    </button>
                </div>
            </div>
        </StyAuthPasswordPopup>
    )
};

export default AuthPasswordPopup;