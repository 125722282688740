import React from 'react';
import styled from "styled-components";
import Spinner from "./Spinner";

const StyButton = styled.button`
    cursor: pointer;
    text-transform: uppercase;
    font-family: Inter, SansaStd,serif;
    font-size: ${props => props.size === "small" ? "12px" : "14px"};
    font-weight: 600;
    margin: 8px 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: ${props => props.size === "small" ? "6px 14px" : "12px"}; 
    width: ${props => props.size !== "small" && "100%"};
    border-radius: ${props => props.size === "small" ? "14px/50%" : "20px/50%"}; 
    > * {
        margin: 0 4px;
    }
    
    color: ${props => props.variant === "outlined" ? "#000" : "#fff"};
    background-color: ${props => props.variant === "outlined" ? "#fff" : "#3B9CDB"};
    *  &:hover {
        background-color: ${props => props.variant === "outlined" ? "#fff" : "#3B9CDB"};
    };
    border: ${props => props.variant === "outlined" ? "2px solid #000" : "2px solid #3B9CDB"};
    
    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
    
`;

const Button = (props) => {
    const { loading, ...rest } = props;

    return (
        <StyButton {...rest}>
            {props.loading
                ? <Spinner variant={"button"}/>
                : props.children}
        </StyButton>
    )
};

export default Button;