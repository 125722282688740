import React, {useState} from 'react';
import styled, {css} from "styled-components";
import InputErrorMsg from "./InputErrorMsg";

const transitionUp = css`
    font-weight: 600;
    transform: translate(10px, -5px);
    font-size: 12px;
    left: 8px;
    top: 24px;
    color: ${props => props?.error && "#A80000"};
`;

const transitionDown = css` 
    font-weight: 400;
    font-size: 18px;
    left: 18px;
    top: 32px;
    transition: 0.1s;
    color: ${props => props?.error && "#A80000"};
`;

const StyInputWrapper = styled.div`
    min-height: ${props => props.slim ? "80px" : "120px"};

    > :nth-child(1) {
        position: relative;
        display: flex;
        flex-direction: column;

        input {
            margin: 8px 0 6px 0;
            border-radius: 10px;
            outline: none;
            border: ${props => props.error ? "2px solid #A80000" : "1px solid #D1D5D7"};
            transition: 0.1s;
            font-size: 18px;
            padding: 32px 16px 16px 16px;

            &:focus {
                border: 2px solid #3B9CDB;
            }

            &:focus:read-only {
                border: 2px solid #D1D5D7;
            }
            
            &:read-only {
                cursor: default;
            }

            &:focus ~ span {
                ${transitionUp};
            }

            &:focus ~ p {
                display: block;
            }
        ;

            &:disabled ~ span {
                ${transitionUp};
                color: #627287
            }
        ;

            &:disabled {
                background-color: #F9FAFC;
            }
        ;
        }

        img {
            cursor: pointer;
            height: 20px;
            position: absolute;
            right: 16px;
        }

        span {
            pointer-events: none;
            position: absolute;
            ${props => ( props.value?.length === 0 || props.value === undefined) ? transitionDown : transitionUp};
        }
        
        p {
            display: none;
            font-weight: 600;
            font-size: 16px;
            color: #3B9CDB;
        }
    }
`;

const InputWrapper = (props) => {
    const { viewPassword, ...rest } = props;
    const [showPassword, setShowPassword] = useState(false)

    return (
        <StyInputWrapper {...rest}>
            <div>
                {props.children}
                <span>{props.label}</span>
                {
                    props.viewPassword &&
                    <img style={{height: "36px", top: "26px"}}
                        src={ showPassword ? "/images/icons/eye_on_blue.png" :"/images/icons/eye_off_blue.png"}
                        alt={"show password"}
                        onClick={() => {props.viewPassword(); setShowPassword(!showPassword)}}
                    />
                }
                {
                    props.onEdit &&
                    <img style={{height: showPassword ? "20px" : "24px", top: showPassword ? "40%" : "32px"}}
                         src={"/images/icons/edit.svg"}
                         alt={"edit"}
                         onClick={() => {props.onEdit()}}
                    />
                }
                <p>{ (props.infoMsg && (props.value?.length === 0 || props.value === undefined) && !props.error ) && props.infoMsg}</p>
            </div>

            {props.error && <InputErrorMsg label={props.error.message}/>}

        </StyInputWrapper>
    )
};

export default InputWrapper;