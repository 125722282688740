import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const StyNoData = styled.div`
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 600;
    color: #9f9f9f;
`;

const NoData = (props) => {
    const {t} = useTranslation()

    return (
        <StyNoData {...props}>
            {t("No data")}
        </StyNoData>
    )
};

export default NoData;