import React from 'react';
import styled from "styled-components";
import Fonts from "../constants/Fonts";
import ScreenWidths from "../constants/ScreenWidths";
import GhostButton from "./GhostButton";
import UseMediaQuery from "../hooks/UseMediaQuery";
import Button from "./Button";
import {useTranslation} from "react-i18next";

const StyPopup = styled.div`
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;



    .popup-root {
        max-width: 600px;
        margin: 16px;
        position: relative;
        box-shadow: 0 10px 40px 2px rgba(0, 0, 0, 0.4);
        border-radius: 8px;
        padding: 24px 32px;
        @media (width < ${ScreenWidths.xs}) {
            max-width: 320px;
            padding: 20px;
            border-radius: 0;
        }
        background-color: #FFF;

        .title {
            ${Fonts.lb}
        }

        .children {
            margin: 8px 0 16px 0;
            padding: 4px 4px 8px 4px;
            color: #3C3C43
        }
        
        .buttons-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                text-transform: uppercase;
                font-size: 14px;
                margin: 0 4px
            }
        }

        .close-cross-btn {
            position: absolute;
            color: black;
            top: 12px;
            right: 12px;
            border: none;
        }
    }
`;

const Popup = (props) => {
    const isSmallDevice = UseMediaQuery(`(width < ${ScreenWidths.xs})`);
    const {t, i18n} = useTranslation()

    return (
        props.open &&
            (
                <StyPopup>
                    <div className={"popup-root"}>
                        <p className={"title"} >{props.title} </p>

                        <div className={"children"}>{props.children}</div>


                        <div className={"buttons-wrapper"}>
                            {props.leftBtAction &&
                                <div className={"default-buttons"}>
                                    {
                                        isSmallDevice
                                        ?
                                        <GhostButton onClick={() =>  props.leftBtAction()} label={props.leftBtLabel} fontColor={"#5856D6"}/>
                                        :
                                        <Button variant={"outlined"} size={"small"} onClick={() => props.leftBtAction()}>{t(props.leftBtLabel)}</Button>
                                    }
                                </div>

                            }

                            {props.rightBtAction &&
                                <div className={"default-buttons"}>
                                    {
                                        isSmallDevice
                                        ?
                                        <GhostButton onClick={() =>  props.rightBtAction()} label={props.rightBtLabel} fontColor={"#5856D6"}/>
                                        :
                                        <Button size={"small"} onClick={() => props.rightBtAction()}>{t(props.rightBtLabel)}</Button>
                                    }
                                </div>
                            }
                        </div>

                        {props.onClose &&
                            <button className={"close-cross-btn"} type={"button"} onClick={() => {props.onClose()}}>
                                <img src={"/images/icons/close.png"} alt={"close button"} height={24}/>
                            </button>
                        }
                    </div>

                </StyPopup>
            )
    )
};

export default Popup;