import jwt from "jsonwebtoken";
import decode from "jwt-decode";
import config from "../../config";
import {useLocation} from "react-router-dom";

const UseAuth = (props) => {
    const location = useLocation()
    const token = localStorage.getItem('auth-token');
    const strArr = location.search.slice(1).split("=")

    const isAccessGranted = (decoded) => {
        return ((decoded.applications || []).filter(app => app.includes('Cardholder'))).length > 0;
    };

    const isTokenExpired = (decoded) => {
        try {
            return decoded.exp < (Date.now() / 1000);
        } catch (err) {
            return false;
        }
    };

    const isLoggedIn = () => {
        if (!!token) {
            const decoded = decode(token);
            return !isTokenExpired(decoded) && isAccessGranted(decoded);
        } else if (!!jwt.decode(strArr[1])) {
            const decoded = jwt.decode(strArr[1]);
            decoded && localStorage.setItem("auth-token", strArr[1]);
            return !isTokenExpired(decoded) && isAccessGranted(decoded);
        } else {
            return false;
        }
    }


    const renewToken = () => {
        const decodedToken = jwt.decode(token);
        if (token && (decodedToken.exp - (Date.now() / 1000)) < 1500) {
            fetch(
                `${config.BACKEND_URL}/auth/token/renew`,
                {
                    method: 'POST',
                    headers: {"Content-Type": "application/json", "Authorization": `Bearer ${token}`}
                }
            ).then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            const decoded = jwt.decode(json.accessToken);
                            if (decoded != null) {
                                localStorage.setItem('auth-token', json.accessToken);
                            }
                        });
                    }
                }
            );
        }
    }

    return {isLoggedIn, renewToken, isTokenExpired, isAccessGranted}
};

export default UseAuth;