import React from 'react';
import styled from "styled-components";

const StyToggleWrapper = styled.label`
    display: block;
    width: 45px;
    height: 25px;
    cursor: pointer;
    position: relative;
    
    input[type="checkbox"] {
        display: none;
        &:checked+div {
            background-color: #3B9CDB;
            div {
                left: calc(100% - 19px - 3px);
            }
        }
    }
    
    > div {
        background-color: #ccc;
        position: absolute;
        border-radius: 100px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        > div {
            position: absolute;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;

            width: calc(25px - 6px);
            height: calc(25px - 6px);
            border-radius: 50%;
            left: 3px;
            top: 3px;
            background-color: #fff;
        }
    }
    
`;

const ToggleWrapper = (props) => {

    return (
        <StyToggleWrapper {...props}>

            {props.children}

            <div>
                <div></div>
            </div>

        </StyToggleWrapper>
    )
};

export default ToggleWrapper;