import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import SwitchLanguage from "./SwitchLanguage";
import PersonalInformation from "./PersonalInformation";
import ChangePassword from "./ChangePassword";
import HomeBanner from "../components/HomeBanner";

const StyDetails = styled.div`
    
`;

const Details = (props) => {
    const {t, i18n} = useTranslation()


    return (
        <StyDetails {...props}>

            <SwitchLanguage/>

            <PersonalInformation/>

            <ChangePassword/>


        </StyDetails>
    )
};

export default Details;