import React from "react";

export default {
    xlb: { fontSize: "1.5rem", fontWeight: 600, padding: "4px" },

    lb: { fontSize: "1.25rem", fontWeight: 600, padding: "4px" },

    m: { fontSize: "1rem" },
    mb: { fontSize: "1rem" , fontWeight: 600, padding: "4px" },

    s: { fontSize: '0.875rem', },
    sb: { fontSize: '0.875rem', fontWeight: 600, padding: "4px" },

    xs: { fontSize: '0.75rem' },
    xsb: { fontSize: '0.75rem', fontWeight: 600, padding: "4px" },
};