import React, {useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import * as jwt from "jsonwebtoken";
import {Link} from "react-router-dom";
import Colors from "../constants/Colors";

const StyMobileMenu = styled.div`
    min-height: ${props => props.open && "100%"};
    position: fixed;
    z-index: 3000;
    top: 0;
    width: 100%;
    background-color: #fff;

    ul {
        padding: 0 16px;
        li:nth-child(1){
            padding: 24px 0;
        }
    }

    li, li * {
        display: flex;
        align-items: center;
    }

    a {
        width: 100%;
        text-decoration: none;
        color: #30363F;

        justify-content: space-between;
    }

    li {
        margin: 0;
        align-items: center;
        border-bottom:  2px solid ${Colors.greyLightTwo};
        img {
            margin-right: 8px;
            height: 24px;
        }
        a {
            padding: 24px 0;
        }
    }

    li a > img {
        height: 16px;
    }

    .epassi-logo {
        min-height: 68px;
        display: flex;
        padding: 12px 16px 12px 8px;
        box-shadow: ${props => !props.open && "0px 10px 40px 2px #d7d7d7"};
        justify-content: space-between;
        button {
            background-color: transparent;
            border: none;
        }
    }

    .logout {
        margin: 16px;
        button {
            text-transform: uppercase;
            font-family: 'Inter, SansaStd', sans-serif;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            color: white;
            padding: 16px 0;
            width: 100%;
            background-color: ${Colors.lightFreshBlue};
            border-radius: 24px / 50%;
        }
    }
`;

const MobileMenu = (props) => {
    const { t } = useTranslation();
    const decoded = jwt.decode(localStorage.getItem('auth-token'));
    const [open, setOpen] = useState(false)

    return (
        <StyMobileMenu {...props} open={open}>
            <div className={"epassi-logo"}>
                <Link to={"home"} onClick={() => setOpen(false)}>
                    <img src={"/images/icons/epassi-logo.svg"} alt="home"/>
                </Link>
                <button onClick={() => setOpen(!open)}>
                    <img src={open ? "/images/icons/close-hamburger.svg" : "/images/icons/hamburger.svg" } alt="close"/>
                </button>
            </div>

            { open &&
                <>
                    <ul>
                        <li>
                            <img src={"/images/icons/user.svg"} alt="user icon"/>
                            <div>{decoded?.userDetails?.firstName + " " + decoded?.userDetails?.lastName}</div>
                        </li>
                        <li>
                            <Link to={"settings"} onClick={() => setOpen(false)}>
                                <div>
                                    <img src={"/images/icons/settings.png"} alt="settings"/>
                                    <div>{t("My settings")}</div>
                                </div>
                                <img src={"/images/icons/right-arrow.svg"} alt="arrow"/>
                            </Link>
                        </li>
                        <li>
                            <Link to={"support"} onClick={() => setOpen(false)}>
                                <div>
                                    <img src={"/images/icons/question-mark.png"} alt="support"/>
                                    <div>{t("Support")}</div>
                                </div>
                                <img src={"/images/icons/right-arrow.svg"} alt="arrow"/>
                            </Link>
                        </li>
                    </ul>

                    <div className={"logout"}>
                        <button  onClick={() => {localStorage.removeItem('auth-token'); window.location.reload()}}>
                            <div>{t("log out")}</div>
                        </button>
                    </div>
                </>
            }
        </StyMobileMenu>
    )
};

export default MobileMenu;