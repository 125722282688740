

export default {
    hasBlankSpace: /^\S*$/i,
    isUppercase: /(?=.*[A-Z])/,
    isLowercase: /(?=.*[a-z])/,
    isSpecial: /(?=.*\W)/,
    minLength: /(?=.{8,}$)/,
    isNumber: /^[0-9]+$/i,
    hasLeadingZero: /^(0|[1-9][0-9]{0,9})$/
}