import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Colors from "../constants/Colors";
import AuthPopup from "./AuthPopup";


const getColor = (variant) => {
    switch (variant) {
        case "error": return {color: "#A80000", icon: "/images/icons/alert_triangle.svg"};
        case "success": return {color: Colors.greenSuccess, icon: "/images/icons/success_circle.svg"};
        case "alert": return  {color: Colors.attentionAlert, icon: "/images/icons/alert_circle.svg"};
        case "info": return {color: Colors.lightFreshBlue, icon: "/images/icons/alert_circle.svg"};
        default: return {color: Colors.attentionAlert, icon: "/images/icons/alert_circle.svg"}
    }
}

const HomeBanner = (props) => {

    const [theme, setTheme] = useState(null)
    const [visible, setVisible] = useState( !localStorage.getItem("notification" + props.id))

    useEffect(() => {
        setTheme(getColor(props.variant))
    },[props.variant])


    const StyHomeBanner = styled.div`
        padding: 0;
        display: flex;
        min-height: 84px;
        width: 100%;
        border: 1px solid #d9d9de;
        border-radius: 8px;
        margin-bottom: 16px;
        
        > :nth-child(1) {
            width: 48px;
            background-color: ${theme?.color};
            border-radius: 8px 0 0 8px;
            align-items: center;
            justify-content: center;
            display: flex;
        }

        > :nth-child(2) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            padding: 12px;
            border-radius: 0 8px 8px 0;
            background-color: #FFF;
            :nth-child(1) {
                color: ${theme?.color};
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
            };
            :nth-child(2) {
                color: ${Colors.blackLightPrimary};
                font-size: 14px;
                line-height: 16px;
            }
        }
        
        > :nth-child(3) {
            > button {
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 24px;
                width: 24px;
                margin: 8px;
                cursor: pointer;
                background: transparent;
                &:hover {
                    background-color: transparent;
                }
            }
            > div {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: 12px;
                img {
                    height: 12px;
                    width: 12px;
                }
            }
            
        }
    `;

    return (
        <>
            { (visible && theme !== null) &&
                <StyHomeBanner {...props}>
                    <div>
                        <object data={theme?.icon}/>
                    </div>
                    <div>
                        <div>
                            {props.title}
                        </div>
                        <div>
                            {props.content}
                        </div>
                    </div>
                    <div>
                        {props.onClick
                            ?
                            <div>
                                <img src={"/images/icons/right-arrow.svg"} alt={"continue"} height={16}/>
                            </div>
                            :
                            <button onClick={() => {setVisible(false); props.id && localStorage.setItem("notification" + props.id, "true")}}>
                                <img src={"/images/icons/close.svg"} alt={"close button"} height={24}/>
                            </button>
                        }
                    </div>

                </StyHomeBanner>
            }
        </>

    )
};

export default HomeBanner;