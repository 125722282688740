import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Popup from "../components/Popup";
import JsonApi from "../../components/JsonApi";
import config from "../../static/config";
import Button from "../components/Button";
import Spinner from "../components/Spinner";
import GhostButton from "../components/GhostButton";

const api = new JsonApi(config.BACKEND_URL);

const StyBankId = styled.div`
    .close-bankid {
        margin-top: 32px;
    }
`;

function StyGhostButton() {
    return null;
}

const BankId = (props) => {

    const {t} = useTranslation();
    const [status, setStatus] = useState('starting');
    const [qrCode, setQrCode] = useState()
    const [intervalHandle, setIntervalHandle] = useState(0);
    let intervalId;

    const [bankIdAuth, setBankIdAuth] = useState()
    const [intervalHandle2, setIntervalHandle2] = useState(0);
    let intervalId2;

    useEffect(() => {
        setStatus('starting');
        if (props.open) {
            handleLogin();
            handleLoginSameDevice();
        }
    }, [props.open]);

    const handleLogin = () => {
        let payload = { authSource: "YGGDRASIL_CARDHOLDER" };

        intervalId = setInterval(() => {
            api.post('/auth/auth/bankid/v2/qrcode', payload).then(response => {
                payload.authId = response.authId;
                setQrCode(response.qrCode);
                if (response.status === 'complete') {
                    clearInterval(intervalId);
                    clearInterval(intervalId2);
                    handleClose();
                    setStatus('complete')
                    props.handleAuthComplete(response);
                } else if (response.status === 'failed') {
                    props.handleAuthFailed(response);
                    handleClose();
                    clearInterval(intervalId);
                    clearInterval(intervalId2);
                    setStatus('failed')
                } else if (response.status === 'pending' && response.hintCode === 'userSign') {
                    if (status !== 'waiting') {
                        setStatus('waiting');
                    }
                }
            }).catch(error => {
                handleClose();
                clearInterval(intervalId);
                clearInterval(intervalId2);
                if (error.status === 401) {
                    props.handleAuthFailed({hintCode: 'unauthorized'});
                } else {
                    props.handleAuthFailed({hintCode: ''});
                }
            });
        }, 2000);

        setIntervalHandle(intervalId);
        setStatus('starting')
    };

    const handleLoginSameDevice = () => {
        let payload = { authSource: "YGGDRASIL_CARDHOLDER" };

        api.post( '/auth/auth/bankid/v2/start', payload).then(res => {
            setBankIdAuth(res)

            intervalId2 = setInterval(() => {
                api.post('/auth/auth/bankid/v2/collect', { orderRef: res.orderRef }).then(collect => {
                    if (collect.status === 'complete') {
                        handleClose();
                        clearInterval(intervalId2);
                        clearInterval(intervalId);
                        setStatus('complete')
                        props.handleAuthComplete(collect);
                    } else if (collect.status === 'failed') {
                        props.handleAuthFailed(collect);
                        handleClose();
                        clearInterval(intervalId2);
                        clearInterval(intervalId);
                        setStatus('failed')
                    } else if (collect.status === 'pending' && collect.hintCode === 'userSign') {
                        if (status !== 'waiting') {
                            setStatus('waiting');
                        }
                    }
                }).catch(error => {
                    handleClose();
                    clearInterval(intervalId2);
                    clearInterval(intervalId);
                    if (error.status === 401) {
                        props.handleAuthFailed({hintCode: 'unauthorized'});
                    } else {
                        props.handleAuthFailed({hintCode: ''});
                    }
                });
            }, 2000);

            setIntervalHandle2(intervalId2);
            setStatus('starting')
        });
    };

    const handleClose = () => {
        setQrCode(null);
        clearInterval(intervalHandle);

        setBankIdAuth(null)
        clearInterval(intervalHandle2);

        props.setOpen(false)
    }


    return (
        <StyBankId {...props}>
            <Popup
                title={t("Log in with Mobile BankID")}
                open={props.open}
            >

                { status === 'starting' &&
                    <>
                        <ol style={{margin: "24px" }}>
                            <li>{t("Open the BankID app")}</li>
                            <li>{t("Click on the scan QR code button")}</li>
                            <li>{t("Point the camera to the QR code")}</li>
                        </ol>

                        <div style={{textAlign: "center"}}>
                            {
                                qrCode
                                    ?
                                    <img src={`data:image/jpeg;base64,${qrCode}`} height="280" alt={""}/>
                                    :
                                    <Spinner  margin={"142px 0"}/>
                            }
                        </div>

                        <GhostButton
                            align={"center"}
                            label={t("BankID on the same device")}
                            href={`bankid:///?autostarttoken=${bankIdAuth?.autoStartToken}&redirect=null`}
                        />
                    </>
                }

                { status === 'waiting' &&
                    <div style={{maxWidth: 320}}>
                        <p>{t("Enter your security code in the BankID app and select Identify")}</p>

                        <Spinner  margin={"112px 0 80px 0"}/>
                    </div>
                }

                { status === 'starting' &&
                    <Button className={"close-bankid"} onClick={handleClose}>
                        <div>{t("Close")}</div>
                    </Button>
                }

            </Popup>
        </StyBankId>
    )
};

export default BankId;