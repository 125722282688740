import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ScreenWidths from "../constants/ScreenWidths";

const StySupport = styled.div`
    margin: 0 auto;
    @media (width < ${ScreenWidths.xs}){
        margin: 0 8px;
    }
    max-width: 490px;
    
    > P {
        font-weight: 600;
        margin: 16px 0;
    }
    
    > button {
        display: flex;
        border: 2px solid #D1D5D7;
        padding: 8px;
        border-radius: 50%;
        img {
            height: 14px;
            width: 14px;
        }
    }
    
    > a {
        text-decoration: none;
        &:hover {
            cursor: pointer;
            
        };
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid #D1D5D7;
        padding: 16px;
        border-radius: 12px;
        div {
            :nth-child(1) {
                margin-bottom: 6px;
                font-weight: 600;
                color: #6C10B9
            };
            :nth-child(2) {
                font-size: 14px;
                color: #4D5D70
            };
        }
        img {
            margin: 0 4px 0 20px;
            height: 12px;
            width: 12px;
        }
    }
`;

const Support = (props) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    return (
        <StySupport {...props}>
            <button onClick={() => navigate(-1)}>
                <img src={"images/icons/back-arrow.svg"} alt={"go to page"}/>
            </button>

            <p>{t("Support")}</p>

            <a href={"https://www.epassi.se/anstalld/lunchkort/faq"} target={"_blank"}>
                <div>
                    <p>{t("How can we help you?")}</p>
                    <p>{t("Read Frequently Asked Questions or contact us")}</p>
                </div>
                <img src={"images/icons/arrow-right.svg"} alt={"go to page"}/>
            </a>
            <a href={"https://www.epassi.se/anvandarvillkor-lunchkort"} target={"_blank"}>
                <div>
                    <p>{t("Terms & Conditions")}</p>
                    <p>{t("You can find the detailed Terms & Conditions from here")}</p>
                </div>
                <img src={"images/icons/arrow-right.svg"} alt={"go to page"}/>
            </a>
            <a href={"https://support.epassi.se/en/support/tickets/new"} target={"_blank"}>
                <div>
                    <p>{t("Support ticket")}</p>
                    <p>{t("File a support ticket and we’ll get back to you")}</p>
                </div>
                <img src={"images/icons/arrow-right.svg"} alt={"go to page"}/>
            </a>
        </StySupport>
    )
};

export default Support;