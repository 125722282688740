import React, {useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import ScreenWidths from "../constants/ScreenWidths";
import HorizontalMenu from "../components/HorizontalMenu";
import HomeBanner from "../components/HomeBanner";
import {useQueryRequest} from "../hooks/UseJsonApi";

const StySupport = styled.div`
    margin: 0 auto;
    max-width: 520px;
    
    > :nth-child(1) {
        margin: 0 8px 12px 8px;
        > p {
            font-weight: 600;
            margin: 16px 0 16px 0;
            font-size: 18px;
        }

        > button {
            display: flex;
            border: 2px solid #D1D5D7;
            padding: 8px;
            border-radius: 50%;
            img {
                height: 14px;
                width: 14px;
            }
        }
    }

    > :nth-child(3) {
        margin: 32px 8px 0 8px;
    }
`;

const Support = (props) => {
    const mapStr = (str) => {
        switch (str) {
            case t("User"): return "user"
            case "/settings/user": return t("User")
            case t("Notifications"): return "notifications"
            case "/settings/notifications": return t("Notifications")
            case t("Limits"): return "limits"
            case "/settings/limits": return t("Limits")
            default: return "details"
        }
    }

    const getChInformation = useQueryRequest({
        url:"/cardholder/info/",
        queryKey: ["Settings"]
    })


    const location = useLocation()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const items = [t("User"),t("Notifications"),t("Limits")]
    const [itemSelected, setItemSelected] = useState(mapStr(location.pathname))


    return (
        <StySupport {...props}>

            <div>
                <button onClick={() => navigate("/home")}>
                    <img src={"/images/icons/back-arrow.svg"} alt={"go to page"}/>
                </button>

                <p>{t("Settings")}</p>

                { getChInformation.data?.personalInformation?.contactInfos.some( item => new Date(item.modifiedDate) <  new Date().setMonth(new Date().getMonth() - 6)) &&
                    <HomeBanner
                        variant={"info"}
                        title={t("Update your contact details")}
                        content={t("Update your details from below. Keeping them up to date will help us to keep your account secure.")}
                    />
                }
            </div>

            <HorizontalMenu
                arr={items}
                selected={itemSelected}
                onSelect={(item) => { setItemSelected(t(item)); navigate(mapStr(item)) }}
            />

            <Outlet/>

        </StySupport>
    )
};

export default Support;