import React, {useState} from 'react';
import styled from "styled-components";
import {capitalize, truncateStr} from "../../components/HelperFunctions";
import UseMediaQuery from "../hooks/UseMediaQuery";
import ScreenWidths from "../constants/ScreenWidths";
import {useTranslation} from "react-i18next";
import ExpandableRow from "./ExpandableRow";

const StyTable = styled.table`
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
    color: #30363F;
    
    > tbody > {
        &:nth-child(even) {
            background-color: #F9FAFC;
        }
        
        > tr:hover {
            cursor: pointer
        }
        
        > tr {
            width: 100%;
        }
    }
    
    td {
        padding: 0 8px;
        border: none;
        img {
            height: 20px;
            width: 20px;
            filter: brightness(0%);
        }
        
        & > div:nth-of-type(1) {
            padding: 0 0 4px 0;
            display: flex;
            justify-content: space-between;
        }

        & > :nth-child(2) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            div {
                display: flex;
                flex-direction: column;
                margin: 8px 48px 8px 0; 
                 & > :nth-child(1) {
                     margin-bottom: 2px;
                     font-size: 12px;
                     font-weight: 600;
                     color: #4D5D70;
                } 
            }
        }
    }
    
    
    .table-exp-cell {
        padding: 0 4px 8px 4px;
    }
`;

const Table = (props) => {
    const isSmallDevice = UseMediaQuery(`(width < ${ScreenWidths.xs})`);
    const {t, i18n} = useTranslation()
    const [expandedId, setExpandedId] = useState()

    const formatCurrency = (str) => {
        return new Intl.NumberFormat(i18n.language, { style: 'currency', currency: 'SEK' }).format(str.toString().replace("-",""))
    }

    return (
        <StyTable>
            <tbody>
                { props.items?.map((item, index) => {
                    return (
                        <ExpandableRow
                            setExpandedId={setExpandedId}
                            expandedId={expandedId}
                            content={
                                <tr key={item.id}>
                                    <td style={{ minWidth: "104px"}}>{new Date(item.transactionDate).toLocaleDateString(undefined, {year: "numeric" ,month: "2-digit", day: "2-digit"})}</td>
                                    <td width={"99%"}>{capitalize( isSmallDevice ? truncateStr(item.description, 8) : item.description) }</td>
                                    <td align={"right"} key={item} style={{color: item.operation === "Top-up" ? "#129649" : item.status === "Declined" ? "#A80000" : "inherit", minWidth: "120px"}}>
                                        {
                                            item.operation === "Purchase" &&
                                            <>
                                                {formatCurrency(item.settlementAmount.amount)}
                                            </>
                                        }
                                        {
                                            item.operation === "Top-up" &&
                                            <>
                                                {"+ " + formatCurrency(item.settlementAmount.amount)}
                                            </>
                                        }
                                        {
                                            item.operation === "Fee" &&
                                            <>
                                                {"- " + formatCurrency(item.feeAmount.amount)}
                                            </>
                                        }
                                        {
                                            item.operation === "Manual Deduction" &&
                                            <>
                                                {"- " + formatCurrency(item.settlementAmount.amount)}
                                            </>
                                        }
                                        {
                                            item.operation === "Refund" &&
                                            <span style={{color: "#129649"}}>
                                                {"+ " + formatCurrency(item.settlementAmount.amount)}
                                            </span>
                                        }
                                    </td>
                                </tr>
                            }
                            expanded={
                                <tr key={item.id}>
                                    <td>
                                        <div>
                                            <div>{capitalize(item.description)}</div>
                                        </div>
                                        <div>
                                            <div>
                                                <span>{t("Date")}</span>
                                                <span >{new Date(item.transactionDate).toLocaleDateString(undefined, {year: "numeric" ,month: "2-digit", day: "2-digit"})}</span>
                                            </div>
                                            <div>
                                                <span>{t("Amount")}</span>
                                                {
                                                    item.operation === "Purchase" &&
                                                    <>
                                                        {formatCurrency(item.settlementAmount.amount)}
                                                    </>
                                                }
                                                {
                                                    item.operation === "Top-up" &&
                                                        <span style={{color: "#129649"}}>
                                                            {"+ " + formatCurrency(item.settlementAmount.amount)}
                                                            {item.transactionAmount.currency !== "SEK" && " / " + formatCurrency(item.transactionAmount.amount) + " " + item.transactionAmount.currency.toLowerCase()}
                                                        </span>
                                                }
                                                {   item.operation === "Fee" &&
                                                        <span>
                                                            {"- " + formatCurrency(item.feeAmount.amount)}
                                                        </span>
                                                }
                                                {   item.operation === "Manual Deduction" &&
                                                    <span>
                                                        {"- " + formatCurrency(item.settlementAmount.amount)}
                                                    </span>
                                                }
                                                {   item.operation === "Refund" &&
                                                     <span style={{color: "#129649"}}>
                                                        {"+ " + formatCurrency(item.settlementAmount.amount)}
                                                    </span>
                                                }
                                            </div>
                                            <div>
                                                <span>{t("Type")}</span>
                                                <span >{item.operation}</span>
                                            </div>
                                            {
                                                item.transactionAmount.currency !== "SEK" &&
                                                <div>
                                                    <span>{t("Conversion fee")}</span>
                                                    <span >{"2.95 %"}</span>
                                                </div>
                                            }

                                            <div>
                                                <span>{t("state")}</span>
                                                <span style={{color: item.status === "Successful" ? "#129649" : item.status === "Declined" ? "#A80000" : "inherit"}} >{t(item.status)}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            }
                        />
                    )
                })}
            </tbody>
        </StyTable>
    )
};

export default Table;