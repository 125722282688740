import React, {useState} from 'react';
import styled from "styled-components";
import {useQueryRequest} from "../hooks/UseJsonApi";
import Font from "../components/Font";
import {useTranslation} from "react-i18next";
import Colors from "../constants/Colors";
import {svDate, truncateStr} from "../../components/HelperFunctions";
import ScreenWidths from "../constants/ScreenWidths";
import Spinner from "../components/Spinner";
import GhostButton from "../components/GhostButton";


const StyArticles = styled.div`
    > :nth-child(1){
        margin: 24px 4px 4px 4px
    }
    .articles {
        display: flex;
        flex-wrap: wrap;
    }
    .item {
        overflow: hidden;
        border-radius: 12px;
        margin: 6px;
        min-height: 260px;
        border: 2px solid ${Colors.greyLightThree};
        flex-grow: 1;
        @media (width > ${ScreenWidths.xs}){
            flex-basis: calc(33.333333% - 12px)
        }
        img {
            border-bottom: 2px solid ${Colors.greyLightThree};
            width: 100%;
            height: 126px;
            object-fit: cover;
        }
    }
    a {
        text-decoration: none;
        div {
            padding: 14px;
        }
    }
    .loading {
        margin-top: 16px;
        text-align: center;
    }
`;

const Articles = (props) => {
    const {t} = useTranslation()
    const [page, setPage] = useState(1)

    const getHubspotNews = useQueryRequest({
        url: "/cardholder/hubspot/blog-posts",
        type: "POST",
        body: {
            "limit" : 100,
            //"orderBy" : "-updated",
            "state" : "PUBLISHED",
            "contentGroupId" : "48586194129"
        }
    })

    return (
            <StyArticles {...props}>
                <Font variant={"lb"}>{t("Articles")}</Font>
                {getHubspotNews.isLoading
                ?
                <Spinner height={"240px"}/>
                :
                <>
                    <div className={"articles"}>
                        { getHubspotNews.data?.objects?.sort((a, b) => b.updated - a.updated).slice(0, (page * 3)).map( item => (
                            <div key={item.id} className={"item"}>
                                <a href={item.absolute_url} target="_blank">
                                    <img src={item.featured_image}/>
                                    <div>
                                        <Font>{svDate(item.updated)}</Font>
                                        <Font variant={"xlb"}>{item.label}</Font>
                                        <Font variant={"m"}>{truncateStr(item.meta_description, 68)}</Font>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                    {
                        (page + 1) * 3 < getHubspotNews.data?.total &&
                        <div className={"loading"}>
                            <GhostButton label={t("Load more articles")} onClick={() => setPage(page + 1)} icon={"/images/icons/spinner.svg"}/>
                        </div>
                    }

                </>
                }
            </StyArticles>
    )
};

export default Articles;