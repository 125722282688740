import React, {useContext, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useQueryRequest} from "../hooks/UseJsonApi";
import {AccountContext} from "../../components/AccountContext";
import {SetNotificationValue} from "../hooks/NotificationContext";
import Spinner from "../components/Spinner";

const StyTemporaryBlock = styled.div`
    margin-top: 16px;
    
    > button {
        text-decoration: none;
        &:hover {
            cursor: pointer;
        };
        width: 100%;
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid #D1D5D7;
        padding: 16px;
        border-radius: 12px;
        div {
            text-align: left;
            :nth-child(1) {
                font-size: 16px;
                margin-bottom: 6px;
                font-weight: 600;
                color: #6C10B9
            };
            :nth-child(2) {
                font-size: 14px;
                color: #4D5D70
            };
        }
        img {
            margin: 0 4px 0 20px;
            height: 18px;
            width: 18px;
        }
    }
    
`;

const TemporaryBlock = (props) => {

    const {t} = useTranslation()
    const navigate = useNavigate()
    const { card, setCard } = useContext(AccountContext);
    const {setNotification} = useContext(SetNotificationValue);
    const [triggerQuery, setTriggerQuery] = useState(false)

    const tempBlockCard = useQueryRequest({
        url: "/cardholder/cards/v2/" + card?.referenceCode  +"/temporary-block",
        type: "POST",
        queryKey: ["TemporaryBlock"],
        enabled: triggerQuery,
        onSuccess: (data) => {
            data.status === "BLOCKED"
                ? setNotification({type: "success", message: t("Your card is now temporary blocked")})
                : setNotification({type: "success", message: t("The temporary card block is now removed")})
            setCard(data)
        }
    })

    return (
        <StyTemporaryBlock {...props}>
            {!card?.status || tempBlockCard.isFetching
                ?
                <div>
                    <Spinner margin={"40px"}/>
                </div>
                :
                <button onClick={()=> setTriggerQuery(true)}>
                    <div>
                        <p>{card?.status === 'ACTIVE' ? t("Temporarily block my card") : t("Unblock my card")}</p>
                        <p>{card?.status === "ACTIVE" ? t("You can temporarily block the usage of your card by tapping here.") : t("Your card has been temporarily blocked. You can unblock it by tapping here.")}</p>
                    </div>
                    <img src={"images/icons/lock-icon.svg"} alt={"go to page"}/>
                </button>
            }
        </StyTemporaryBlock>
    )
};

export default TemporaryBlock;