import React from 'react';
import styled from "styled-components";
import {findValue} from  "../constants/Functions";

const StyRadioGroup = styled.div`
    input[type="radio"] {
        accent-color: #000;
        height: 20px;
        width: 20px;
    }
    div {
        margin: 12px 0;
        display: flex;
        align-items: center;
    }
    label {
        padding: 8px 12px;
    }
`;

const RadioGroup = (props) => {

    return (
        <StyRadioGroup>
            <form onChange={(e) => props.onChange(e.target.value)}>
                { props.array?.map((item) => {

                    let id = findValue(item, props.idValue);
                    let label = findValue(item, props.label);

                    return (
                        <div key={id}>
                            <input name="form" id={id} type="radio" value={id}
                                   defaultChecked={id === props.defaultValue}/>
                            <label htmlFor={id}>{label}</label>
                        </div>
                    )
                })}
            </form>
        </StyRadioGroup>
    )
};

export default RadioGroup;