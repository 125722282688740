import React, {useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Popup from "../components/Popup";

const StyCookies = styled.div`
    position: relative;
    .cookies-root {
        > :nth-child(1){
            font-weight: 600;
            text-align: center;
            margin-bottom: 24px;
        }

        > :nth-child(2){
            margin-bottom: 24px;
        }
    }

    .cookies-image {
        position: absolute;
        height: 60%;
        top: 24px;
        right: 24px;
        filter: invert(44%) sepia(22%) saturate(450%) hue-rotate(174deg) brightness(93%) contrast(84%) opacity(5%);
    }
    
`;

const Cookies = (props) => {

    const {t, i18n} = useTranslation();
    const [openCookies, setOpenCookies] = useState(!localStorage.getItem("cookies-consent"))

    return (
        <StyCookies {...props}>
                <Popup
                    open={openCookies}
                    rightBtAction={() => {localStorage.setItem("cookies-consent", JSON.stringify(false)); setOpenCookies(false)}}
                    leftBtAction={() => {localStorage.setItem("cookies-consent", JSON.stringify(true)); setOpenCookies(false)}}
                    rightBtLabel={t("Decline")}
                    leftBtLabel={t("Accept")}
                >
                    <img className={"cookies-image"} src={"/images/icons/cookie.svg"} alt={"cookie image"}/>

                    <div className={"cookies-root"}>
                        <p>{t("Hey")}</p>
                        <p>{t("We just want to inform you that we use cookies")}</p>
                    </div>

                </Popup>

        </StyCookies>
    )
};

export default Cookies;