import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const StyReplacementCard = styled.div`
    margin-top: 24px;
    > button {
        text-decoration: none;
        &:hover {
            cursor: pointer;

        };
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid #D1D5D7;
        padding: 16px;
        border-radius: 12px;
        div {
            text-align: left;
            :nth-child(1) {
                font-size: 16px;
                margin-bottom: 6px;
                font-weight: 600;
                color: #6C10B9
            };
            :nth-child(2) {
                font-size: 14px;
                color: #4D5D70
            };
        }
        img {
            margin: 0 4px 0 20px;
            height: 12px;
            width: 12px;
        }
    }
`;

const ReplacementCard = (props) => {

    const {t} = useTranslation()
    const navigate = useNavigate()


    return (
        <StyReplacementCard {...props}>
            <button onClick={() => navigate("/replacement-card")}>
                <div>
                    <p>{t("Replacement card")}</p>
                    <p>{t("You can order a replacement card from here.")}</p>
                </div>
                <img src={"images/icons/arrow-right.svg"} alt={"go to page"}/>
            </button>
        </StyReplacementCard>
    )
};

export default ReplacementCard;