import React, {useContext, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {AccountContext} from "../../components/AccountContext";
import GhostButton from "../components/GhostButton";
import Spinner from "../components/Spinner";
import NoData from "../components/NoData";
import Table from "../components/Table";
import {useQueryRequest} from "../hooks/UseJsonApi";
import HorizontalMenu from "../components/HorizontalMenu";
import ExpandableRow from "../components/ExpandableRow";

const StyTransactions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .transactionsPage {
        
        .transactions {
            margin-top: 8px;
            border-top: 2px solid #D1D5D7;

            > div {
                border-bottom: 2px solid #D1D5D7;
                > :nth-child(1) {
                    font-weight: 600;
                    padding-left: 8px
                }

            }
        }
    }
`;

const Transactions = (props) => {
    const {t} = useTranslation();
    const monthNames = [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")];
    const [sliceArray, setSliceArray] = useState(5)

    const { account, setAccount } = useContext(AccountContext);
    const currentDate =  new Date();
    const [expandedId, setExpandedId] = useState()
    const [years, setYears] = useState([])
    const [selectedYear, setSelectedYear] = useState(null)

    const transactions = useQueryRequest({
        queryKey: ["Transactions", selectedYear],
        url: `/cardholder/accounts/v2/${account?.id}/transactions/years/${selectedYear}`,
        enabled: !!account && !!selectedYear
    })

    const allYears = useQueryRequest({
        queryKey: ["allYears"],
        url: `/cardholder/accounts/v2/${account?.id}/transactions/years`,
        enabled: !!account && selectedYear === null,
        onSuccess: (years) => {
            setYears(years);
            setSelectedYear(years[0])
        }

    })

    const groupByMonths = () => {
        return Object.values(
            transactions.data.reduce((acc, current) => {
                acc[new Date(current.transactionDate).getMonth()] = acc[new Date(current.transactionDate).getMonth()] ?? [];
                acc[new Date(current.transactionDate).getMonth()].push(current);
                return acc;
            }, {})
        );
    }

    return (
        <StyTransactions {...props}>

            {transactions.isFetching || !account || allYears.isFetching
                ?
                <Spinner margin={"40px 0"}/>
                :
                transactions.data?.length
                    ?
                    <div className={"transactionsPage"}>
                        <HorizontalMenu arr={years} selected={selectedYear} onSelect={(item) => setSelectedYear(item)}/>

                        <div className={"transactions"}>
                            { groupByMonths().length && groupByMonths().reverse().map((item, index) => {
                                let monthName = monthNames[new Date(item[0].transactionDate).getMonth()]
                                return (
                                    <ExpandableRow
                                        bigIcon
                                        defaultExpanded={index === 0}
                                        setExpandedId={setExpandedId}
                                        expandedId={expandedId}
                                        onOpen={ () => {setSliceArray(5)}}
                                        content={
                                            <div>{monthName}</div>
                                        }
                                        expanded={
                                            <>
                                                <Table
                                                    items={item.slice(0, sliceArray)}
                                                />

                                                { (sliceArray === 5 && item.length > 5) &&
                                                    <div style={{margin: "24px 0 16px 0"}}>
                                                        <GhostButton label={t("Load all")} onClick={() => setSliceArray(transactions.data.length)} icon={"/images/icons/spinner.svg"} align={"center"}/>
                                                    </div>
                                                }

                                            </>
                                        }
                                    />
                                )
                            })}
                        </div>
                    </div>
                    :
                    <NoData/>
            }

        </StyTransactions>
    )
};

export default Transactions;