import React, {useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import ScreenWidths from "../constants/ScreenWidths";
import {useNavigate} from "react-router-dom"
import {useQueryRequest} from "../hooks/UseJsonApi";
import InputWrapper from "../components/InputWrapper";
import {useForm} from "react-hook-form";
import Button from "../components/Button";

const StyEditPhone = styled.div`
    margin: 0 auto;
    @media (width < ${ScreenWidths.xs}){
        margin: 0 8px;
    }
    max-width: 520px;

    > :nth-child(1) {
        > p {
            font-weight: 600;
            margin: 16px 0;
            font-size: 18px;
        }

        > button {
            display: flex;
            border: 2px solid #D1D5D7;
            padding: 8px;
            border-radius: 50%;
            img {
                height: 14px;
                width: 14px;
            }
        }
    }
    
    form {
        > p {
            margin: 12px 0 24px 0;
        }
    }
`;

const EditPhone = (props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors, isValid, isSubmitted, isDirty}, reset, getValues, setValue } = useForm({ mode: "all"});
    const [currentPhone, setCurrentPhone] = useState()

    const getPhoneNumber = useQueryRequest({
        queryKey: ["EditPhone"],
        enabled: !isDirty,
        url:"/cardholder/info",
        onSuccess: (data) => {
            setValue("phoneNumber", data?.personalInformation?.contactInfos.find(a => a.contactInformationType.id === 2)?.description )
            setCurrentPhone(data?.personalInformation?.contactInfos.find(a => a.contactInformationType.id === 2))
        }
    })

    const requestVerificationCode = useQueryRequest({
        url:"/cardholder/contact-info/verification-request",
        type: "POST",
        queryKey: ["EditPhone"],
        enabled: isSubmitted,
        body: {
            "contactInformationId": currentPhone?.id || null,
            "contactInformationTypeId": 2,
            "newValue": getValues("phoneNumber")
            //"personalInformationId": getPhoneNumber.data?.personalInformation.id
        },
        onSuccess: (data) => {
            navigate("/verification-code", {state: {contactInformationId: data?.contactInformationId, value: getValues("phoneNumber"), contactInformationType: 2}})
            reset();
        },
        onError: () => {
            reset()
        }
    })

    return (
        <StyEditPhone {...props}>
            <div>
                <button onClick={() => navigate(-1)}>
                    <img src={"/images/icons/back-arrow.svg"} alt={"go to page"}/>
                </button>

                <p>{t("Edit phone number")}</p>
            </div>

            <form onSubmit={handleSubmit( () => {})} autoComplete={"off"}>
                <InputWrapper error={errors.phoneNumber} label={t("Phone number")} value={watch("phoneNumber")} slim={"true"}>
                    <input
                        {...register("phoneNumber", {
                            required: t("This field is required"),
                            //pattern: { value: /^07\d{8}$/i, message: t("Value must be a valid phone number")},

                            validate: {
                                wasChanged: (value) => value !== getPhoneNumber.data?.personalInformation?.contactInfos.find(a => a.contactInformationType.descriptionEnglish === "Phone")?.description ||  t("Change your phone number"),
                                leadingSevenOrPlus: (value) => ( /^07\d{8}$/.test(value) || /^\+[1-9]\d{1,14}$/.test(value) ) ||  t("Value must be a valid phone number")
                            }
                        } )}
                    />
                </InputWrapper>

                <p>{t("We will send you a code to verify the change")}</p>

                <Button
                    type={"submit"}
                    disabled={!isValid}
                    loading={requestVerificationCode.isFetching || getPhoneNumber.isFetching}
                >
                    <div>{t("Send verification code")}</div>
                </Button>
            </form>

        </StyEditPhone>
    )
};

export default EditPhone;