import React, {useContext, useState} from 'react';
import styled from "styled-components";
import Button from "../components/Button";
import {useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import ScreenWidths from "../constants/ScreenWidths";
import GhostButton from "../components/GhostButton";
import InputWrapper from "../components/InputWrapper";
import {useForm} from "react-hook-form";
import {useQueryRequest} from "../hooks/UseJsonApi";
import {SetNotificationValue} from '../hooks/NotificationContext';

const StyLgRecover = styled.div`
    text-align: left;

    > :nth-child(1){
        font-size: 24px;
        font-weight: 600;
        @media (width < ${ScreenWidths.xs}) {
            font-size: 20px;
        }
    }

    > :nth-child(2){
        margin: 8px 0 20px 0;
        font-size: 16px;
        color: #4D5D70;
    }
    
    form {
        > :nth-child(2) {
            margin-bottom: 24px
        }
    }
    
`;

const LgRecover = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors }, reset, getValues } = useForm({mode: "onBlur"});
    const [recoverEmail, setRecoverEmail] = useState(null)
    const {setNotification} = useContext(SetNotificationValue);

    const resetPassword = useQueryRequest({
        noAuth: true,
        url: "/auth/password/reset",
        type: "POST",
        body: {application: "Yggdrasil Cardholder", email: recoverEmail},
        enabled: !!recoverEmail,
        onSuccess: () => {
            setNotification({
                type: "success",
                message: t(`If an account exists for the email address provided, you will get an email with instructions on resetting your password. If it doesn't arrive, be sure to check your spam folder.`),
                timeout: 12000
            })
        }
    })

    const onSubmit = data => {
        setRecoverEmail(data.loginRecover)
        reset()
        setRecoverEmail(null)
    }

    return (
        <StyLgRecover {...props}>
            <p>{t("Reset your password")}</p>
            <p>{t("Have you forgotten your password")}</p>

            <form onSubmit={handleSubmit(onSubmit)}>
                <InputWrapper error={errors.loginRecover} label={t("Email")} value={watch("loginRecover")}>
                    <input
                        {...register("loginRecover", { required: "This field is required", pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Enter valid email address"}})}
                    />
                </InputWrapper>

                <GhostButton label={t("Back to login")} onClick={() => navigate("/login/email")}/>

                <Button type={"submit"} loading={resetPassword.isFetching}>
                    <div>{t("Reset password")}</div>
                </Button>
            </form>
        </StyLgRecover>
    )
};

export default LgRecover;