import React, {useContext, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import InputWrapper from "../components/InputWrapper";
import Button from "../components/Button";
import {useQueryRequest} from "../hooks/UseJsonApi";
import {SetNotificationValue} from "../hooks/NotificationContext";

const StyLimits = styled.div`
    form > p {
        color: #30363F;
        font-weight: 600;
        margin-bottom: 8px;
    }
`;

const Limits = (props) => {

    const {t} = useTranslation()
    const {setNotification} = useContext(SetNotificationValue);
    const [allowLimitChange, setAllowLimitChange] = useState(false);

    const { register, handleSubmit, watch, formState: { errors, isValid, isSubmitted, isDirty}, reset, getValues, setValue } = useForm({ mode: "all"});

    const getPreferences = useQueryRequest({
        queryKey: ["getPreferences"],
        enabled: !isDirty,
        url:"/cardholder/preferences",
        onSuccess: ( data ) => {
            data &&
                setValue( "dailyLimit",data?.dailyPurchaseLimit / 100)
                setValue( "weeklyLimit", data?.weeklyPurchaseLimit / 100)
        }
    })

    const getClientPreferences = useQueryRequest({
        queryKey: ["getClientPreferences"],
        enabled: !isDirty,
        url:"/cardholder/client-preferences",
        onSuccess: ( data ) => {
            data &&
                setAllowLimitChange(!data.allowClientToChangeCardholderLimits)
        }
    })

    const saveLimits = useQueryRequest({
        url:"/cardholder/preferences",
        enabled: isSubmitted,
        type: "POST",
        body: {
            dailyPurchaseLimit: getValues("dailyLimit") === "" ? 0 : getValues("dailyLimit") * 100,
            weeklyPurchaseLimit: getValues("weeklyLimit") === "" ? 0 : getValues("weeklyLimit") * 100},
        onSuccess: () => {
            setNotification({type: "success", message: t("Limits saved successfully")});
            reset();
        }
    })


    return (
        <StyLimits {...props}>

            <form onSubmit={handleSubmit( () => {})}>

                <p>{t("Daily purchase limit (kr)")}</p>
                {
                    allowLimitChange ? 
                    <InputWrapper error={errors.dailyLimit} label={t("Set limit")} value={watch("dailyLimit")}>
                    <input
                        {...register("dailyLimit", {
                            pattern: { value: /^[0-9]*$/i, message: t("Value must be a number without decimals")}
                        } )}
                    />
                    </InputWrapper> : 
                    <p>{getValues("dailyLimit")}</p>
                }

                <p>{t("Weekly purchase limit (kr)")}</p>
                {
                    allowLimitChange ? 

                    <InputWrapper error={errors.weeklyLimit} label={t("Set limit")} value={watch("weeklyLimit")}>
                        <input
                            {...register("weeklyLimit", {
                                pattern: { value: /^[0-9]*$/i, message: t("Value must be a number without decimals")}
                            } )}
                        />
                    </InputWrapper> : 
                    <p>{getValues("weeklyLimit")}</p>
                }

                {
                    allowLimitChange && 
                    <Button type={"submit"} onClick={() => {}} loading={saveLimits.isFetching} disabled={!isValid} >
                        <div>{t("Save changes")}</div>
                    </Button>
                }
                
            </form>

        </StyLimits>
    )
};

export default Limits;