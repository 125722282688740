import React from 'react';
import {BrowserRouter} from "react-router-dom";
import AppBody from "./styled_comps/views/AppBody";
import {AccountProvider} from "./components/AccountContext";
import ReactGA from "react-ga4"
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {NotificationProvider2} from './styled_comps/hooks/NotificationContext';

ReactGA.initialize("G-9MGERHMXD3");

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        },
    },
})

function App() {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <AccountProvider>
                    <NotificationProvider2>
                        <AppBody/>
                    </NotificationProvider2>
                </AccountProvider>
            </QueryClientProvider>
        </BrowserRouter>
    );
}

export default App;


