import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {SetNotificationValue} from "../hooks/NotificationContext";
import {useForm} from "react-hook-form";
import ToogleWrapper from "../components/ToggleWrapper"
import {useQueryRequest} from "../hooks/UseJsonApi";
import {useQueryClient} from "@tanstack/react-query";
import Spinner from "../components/Spinner";
import {shouldMatch} from "../../components/ValidationFunctions";
import {AccountContext} from "../../components/AccountContext";

const StyService = styled.div`
    .notifications-wrapper {
        
        margin-top: 64px;
        
        > :nth-child(1) {
            font-weight: 600;
            margin-bottom: 8px;
        };

        > :nth-child(2) {
            margin-bottom: 24px;
        };

        .separator {
            margin: 32px 0;
            border-top: 2px solid #D1D5D7
        };
        
        .switch {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 18px;

            p {
                font-weight: 600;
            }
        };

       .description {
           margin: -4px 0 24px 0;
       }
    }
`;

const Service = (props) => {
    const {t} = useTranslation()
    const {setNotification} = useContext(SetNotificationValue);
    const { register, watch, formState: {isSubmitted, isDirty, dirtyFields}, reset, getValues, setValue } = useForm({ mode: "all"});
    const [currentQueryKey, setCurrentQueryKey] = useState(null)

    const { disableCalendar } = useContext(AccountContext);

    const objFields = [
        {
            title: "Important card-related messages",
            description: "For example when your card gets replaced or renewed, but also when it gets activated for Google or Apple Pay.",
            arr: [
                {type: "Email", field: "importantCardCommunicationViaEmail"},
                {type: "SMS", field: "importantCardCommunicationViaSms"},
                {type: "App notifications", field: "importantCardCommunicationViaPush"}
            ]
        },
        {
            title: "Special offers and promotions",
            description: "Receive special offers from Epassi and its partners.",
            arr: [
                {type: "Email", field: "specialOffersViaEmail"},
                {type: "SMS", field: "specialOffersViaSms"},
                {type: "App notifications", field: "specialOffersViaPush"}
            ]
        },
        {
            title: "Transactions in foreign currencies",
            description: "Receive information about exchange rates and fees whenever you make a purchase in a foreign currency.",
            arr: [
                {type: "Email", field: "abroadTransactionsViaEmail"},
                {type: "SMS", field: "abroadTransactionsViaSms"},
                {type: "App notifications", field: "abroadTransactionsViaPush"}
            ]
        },
        {
            title: "Low balance",
            description: "Receive a notification if your card balance falls below 200 SEK.",
            arr: [
                {type: "Email", field: "lowOnCreditViaEmail"},
                {type: "SMS", field: "lowOnCreditViaSms"},
                {type: "App notifications", field: "lowOnCreditViaPush"}
            ]
        },
        {
            title: "High balance",
            description: "Receive a notification if your card balance goes above 12 000 SEK. The maximum allowed balance is 15,000 SEK.",
            arr: [
                {type: "Email", field: "highOnCreditViaEmail"},
                {type: "SMS", field: "highOnCreditViaSms"},
                {type: "App notifications", field: "highOnCreditViaPush"}
            ]
        },
        {
            title: "Top-up reminder",
            description: "Receive a reminder before the top-up deadline.",
            arr: [
                {type: "Email", field: "topUpReminderViaEmail"},
                {type: "SMS", field: "topUpReminderViaSms"},
                {type: "App notifications", field: "topUpReminderViaPush"}
            ]
        },
        {
            title: "Balance notification",
            description: "Receive a balance notification every Sunday.",
            arr: [
                {type: "Email", field: "balanceStatementsViaEmail"},
                {type: "SMS", field: "balanceStatementsViaSms"},
                {type: "App notifications", field: "balanceStatementsViaPush"}
            ]
        },
    ]

    const getServices = useQueryRequest({
        queryKey: ["getServices"],
        url:"/cardholder/preferences",
        onSuccess: (data) => {
            setAllValues(data?.notificationMethod)
        }
    })

    const setServices = useQueryRequest({
        queryKey: [currentQueryKey],
        url:"/cardholder/preferences",
        enabled: currentQueryKey !== null &&  currentQueryKey !== "changeAlltrue" && currentQueryKey !== "changeAllfalse",
        type: "POST",
        body: getValues(),
        onSuccess: (data) => {
            setAllValues(data?.preferences?.notificationMethod)
        }
    })

    const setDefaults = useQueryRequest({
        queryKey: [currentQueryKey],
        url:"/cardholder/preferences/set-default",
        enabled: currentQueryKey === "changeAlltrue" && isDirty,
        type: "POST",
        onSuccess: (data) => {
            setAllValues(data?.preferences?.notificationMethod);
        }
    })

    const disableAll = useQueryRequest({
        queryKey: [currentQueryKey],
        url:"/cardholder/preferences/disable-all",
        enabled: currentQueryKey === "changeAllfalse" && isDirty,
        type: "POST",
        onSuccess: (data) => {
            setAllValues(data?.preferences?.notificationMethod);
        }
    })

    const setAllValues = (data) => {
        const preferences = []
        objFields.forEach( item => preferences.push(item.arr))

        preferences.flat().forEach( item => setValue(item.field, data[item.field]));
        currentQueryKey && setNotification({type: "success", message: t("Notification preferences saved successfully")});
    }

    const filteredGetValues = (field) => {
        const copy = {...getValues()};
        delete copy[field];
        return copy
    }

    return (
        <StyService {...props}>
            <div className={"notifications-wrapper"}>

                <p>{t("Communication and notifications")}</p>
                <p>{t("Here you can change the settings to all your notifications and messages coming from Epassi.")}</p>

                { getServices.isFetching
                    ?
                    <Spinner margin={"80px 0 800px 0"}/>
                    :
                    <>
                        <div className={"switch"}>
                            <p>{t("Allow notifications")}</p>
                            <ToogleWrapper>
                                <input
                                    disabled={setDefaults.isFetching || disableAll.isFetching}
                                    checked={Object.values(filteredGetValues("changeAll")).some(item => item === true)}
                                    type="checkbox"
                                    {...register("changeAll", {onChange: (e) => setCurrentQueryKey( "changeAll" + e.target.checked)})}
                                />
                            </ToogleWrapper>
                        </div>

                        { disableAll.isFetching || setDefaults.isFetching
                                ?
                                <Spinner margin={"80px 0 800px 0"}/>
                                :
                                (disableCalendar ? objFields.filter( item => item.title !== "Top-up reminder") : objFields).map( item => {
                                    return (
                                        <div key={item.description}>
                                            <div className={"separator"}></div>
                                            <div className={"switch"}>
                                                <p>{t(item.title)}</p>
                                                <ToogleWrapper>
                                                    <input
                                                        type="checkbox"
                                                        checked={item.arr.some( item => getValues(item.field) === true)}
                                                        onChange={ (e) => {
                                                            item.arr.forEach((innerItem,index,array) =>  {
                                                                setValue(innerItem.field, e.target.checked);
                                                                index === array.length -1 && setCurrentQueryKey(item.title + e.target.checked)
                                                            });

                                                        }}
                                                    />
                                                </ToogleWrapper>
                                            </div>
                                            <p className={"description"}>{t(item.description)}</p>
                                            {
                                                item.arr.map(item => {
                                                    return (
                                                        <div className={"switch"} key={item.field}>
                                                            <p>{t(item.type)}</p>
                                                            <ToogleWrapper>
                                                                <input
                                                                    type="checkbox"
                                                                    {...register(item.field, {onChange: (e) => setCurrentQueryKey(item.field + e.target.checked)})}
                                                                />
                                                            </ToogleWrapper>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                        }
                    </>
                }

            </div>
        </StyService>
    )
};

export default Service;