import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Button from "../components/Button";
import UseMediaQuery from "../hooks/UseMediaQuery";
import ScreenWidths from "../constants/ScreenWidths";

const StyNotFound = styled.div`

    .not-found-root {
        justify-content: center;
        display: flex;
        margin-top: 24px;

        img {
            height: 460px;
        }

        .text-container {
            margin: 0 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > :nth-child(1) {
                margin-bottom: 24px;
                font-weight: 800;
                font-size: 80px;
                color: #c0c2c5
            }

            > :nth-child(2) {
                font-weight: 600;
                font-size: 24px;
                margin-bottom: 4px;
                color: #afb0b4
            }

            > :nth-child(3) {
                margin-bottom: 32px;
                font-weight: 400;
                color: #afb0b4

            }
        }
    }

`;

const NotFound = (props) => {

    const isNotSmallDevice = UseMediaQuery(`(width > ${ScreenWidths.xs})`);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    return (
        <StyNotFound {...props}>
            <div className={"not-found-root"}>
                 { isNotSmallDevice &&
                    <div>
                        <img src={"/images/404_bg.png"} alt={"not found picture"} />
                    </div>
                }

                <div className={"text-container"}>
                    <p>{t("Oops!")}</p>
                    <p>{t("You shouldn't be here.")}</p>
                    <p>{t("404 page not found")}</p>
                    <div>
                        <Button variant={"outlined"} onClick={() => navigate("/home")}>{t("Return to home")}</Button>
                    </div>
                </div>
            </div>
        </StyNotFound>
    )
};

export default NotFound;