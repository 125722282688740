import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Outlet} from "react-router-dom";
import ScreenWidths from "../constants/ScreenWidths";
import {useTranslation} from "react-i18next";
import Select from 'react-select';

const StyLogin = styled.div`
    height: 100vh;
    background-image: url("/images/epassi-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    > div {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;

        > :nth-child(1) {
            position: absolute;
            right: 24px;
            top: 24px
        }
        

        > :nth-child(2) {
            padding: 32px;
            max-width: 500px;
            width: 100%;
            @media (width < ${ScreenWidths.xs}) {
                padding: 32px 20px;
                max-width: 410px;
            }
            margin: 0 8px;
            box-shadow: 0 0 24px 1px #656565;
            border-radius: 8px;
            background-color: #fff;
            text-align: center;

            > img {
                width: 170px;
                margin-bottom: 24px;
            }
        }
    }
`;

const Login = (props) => {
    const { t, i18n } = useTranslation();

    const options = [
        { value: 'en', label: t('English') },
        { value: 'sv', label: t('Swedish') }
    ]

    const [selectedOption, setSelectedOption] = useState(options[1]);

    return (
        <StyLogin {...props}>
            <div>
                <Select
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: 0,
                            boxShadow: 'none',
                            borderRadius: 24,
                            padding: "0 6px",
                            "&:hover": {
                                borderColor: "#fff"
                            }
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            color: "#000",
                            backgroundColor: state.isSelected && "#cccccc",
                        }),
                    }}
                    defaultValue={selectedOption}
                    onChange={(e) => i18n.changeLanguage(e.value)}
                    options={options}
                />
                <div>
                    <img src={"/images/epassi-word.svg"} alt={"epassi-logo"}/>
                    <Outlet/>
                </div>
            </div>
        </StyLogin>
    )
};

export default Login;