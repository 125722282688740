import React from 'react';
import styled from "styled-components";

const variants = {
    xlb: { fontSize: '24px', lineHeight: '32px', fontWeight: 600 },
    lb: { fontSize: '20px', lineHeight: '24px', fontWeight: 600 },
    m: { fontSize: '16px', lineHeight: '24px' },
    mb: { fontSize: '16px', lineHeight: '24px', fontWeight: 600 },
    r: { fontSize: '14px', lineHeight: '16px' },
    rb: { fontSize: '14px', lineHeight: '16px', fontWeight: 600 },
    s: { fontSize: '12px', lineHeight: '15px' },
    sb: { fontSize: '12px', lineHeight: '15px', fontWeight: 600 },
};


const StyFont = styled.p`
    font-family: 'Inter, SansaStd', sans-serif;
    ${props => props.variant ? variants[props.variant] : variants["r"]};
    color: ${props => props.color ? props.color : "#30363F"};
    margin: 0;
    padding: 4px
`;

const Font = (props) => {
    return (
        <StyFont {...props}>
            {props.label}
            {props.children}
        </StyFont>
    )
};

export default Font;