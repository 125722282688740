import React, {useState} from 'react';
import styled from "styled-components";
import Popup from "../components/Popup";
import {useTranslation} from "react-i18next";
import {useQueryRequest} from "../hooks/UseJsonApi";
import {useForm} from "react-hook-form";
import SelectWrapper from "../components/SelectWrapper";
import InputWrapper from "../components/InputWrapper";
import RegexStr from "../constants/RegexStr";

const StyCalculatorPopup = styled.div`
    
    .calculator-popup-wrapper {
        .title {
            margin: 16px 0;
            font-weight: 400;
        }
        
        .wrapper {
            display: flex;
        }
        
        .currency-result {
            margin: 16px 0 24px 0;

            > :nth-child(2) {
                margin-top: 12px;
                font-size: 32px;
                font-weight: 600;
            }
        }
    }
    
    
    
`;

const CalculatorPopup = (props) => {

    const { t } = useTranslation();
    const { register, handleSubmit, watch, formState: { errors, isValid, isSubmitted, isDirty}, reset, resetField, getValues, setValue, setError } = useForm({ mode: "all"});
    const [callExchangeEndpoint, setCallExchangeEndpoint] = useState(false)

    const getCurrencies = useQueryRequest({
        url:"/cardholder/static/currencies",
        queryKey: ["CalculatorPopup"]
    })

    const calculateExchange = useQueryRequest({
        url: "/processor/static/currency-exchange/fx-rates",
        type: "POST",
        queryKey: "CalculatorPopup",
        body: {
            fromAmount: getValues("amount"),
            fromCurrency: getValues("fromCurrency")?.split(",")[0],
            fromCurrencyDescription: getValues("fromCurrency")?.split(",")[1],
            issuerMarkupPercentage: 0.0,
            toCurrency: "SEK", // now we will go only for SEK conversion
            paymentScheme: "VISA" // keep the paymentScheme VISA now
        },
        enabled:  isDirty && isValid && callExchangeEndpoint,
        onSuccess: (data) => {
            setCallExchangeEndpoint(false)
        }
    })

    return (
        <StyCalculatorPopup {...props}>

                <Popup
                    open={props.openPopup}
                    title={t("Currency calculator")}
                    rightBtAction={() => { reset(); props.setOpenPopup(false)}}
                    rightBtLabel={"Close"}
                >
                    <form className={"calculator-popup-wrapper"} onSubmit={handleSubmit( () => {})}>
                        <p className={"title"}>{t("Choose currency and amount to be converted to Swedish crowns.")}</p>

                        <div className={"left-wrapper"}>

                            <InputWrapper error={errors.amount} label={t("Amount")} value={ watch("amount")}>
                                <input
                                    {...register(
                                        "amount",
                                        {
                                            onChange: () => setTimeout(() => { setCallExchangeEndpoint(true)}, "250"),
                                            required: t("This field is required"),
                                            pattern: { value: RegexStr.isNumber , message: t("Value must be a valid number")},
                                            validate: {
                                                leadingZero: (value) => RegexStr.hasLeadingZero.test(value) ||  t("Number can't start with zero"),
                                            }
                                        })}
                                />
                            </InputWrapper>

                            <SelectWrapper inputGroup error={errors.fromCurrency}>
                                <select
                                    {...register(
                                        "fromCurrency",
                                        {
                                            onChange: () => setTimeout(() => { setCallExchangeEndpoint(true)}, "250"),
                                            required: t("This field is required")
                                        })
                                    }
                                    defaultValue={""}>
                                    <option value="" disabled selected hidden>{t("Select currency")}</option>
                                    { getCurrencies.data?.map(item => (
                                        <option key={item.id} value={[item.code, item.description]}>{t(item.description)}</option>
                                    )) }
                                </select>
                            </SelectWrapper>

                        </div>

                        <div className={"currency-result"}>
                            <p>{
                                (getValues("amount") ? getValues("amount") : "0")
                                + " " +
                                (getValues("fromCurrency")?.split(",")[1] ? getValues("fromCurrency")?.split(",")[1] : t("currency"))
                                + " ="}
                            </p>
                            <p>{
                                (calculateExchange.data?.toAmountWithMarkup && getValues("amount") && !errors.amount && getValues("fromCurrency") ? calculateExchange.data?.toAmountWithMarkup : "0")
                                + " " + t("Swedish Crowns")
                            }</p>
                        </div>

                    </form>
                </Popup>
        </StyCalculatorPopup>
    )
};

export default CalculatorPopup;