import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useQueryRequest} from "../hooks/UseJsonApi";
import {AccountContext} from "../../components/AccountContext";
import AuthPopup from "../components/AuthPopup";
import {useLocation} from "react-router-dom";
import Spinner from "../components/Spinner";

const StyShowHidePin = styled.div`
    
    > form {
        cursor: pointer;
        > div {
            background-color: #fff;
            text-align: center;
            width: 68px;
            height: 28px;
        };
        h6 {
            font-weight: 400;
            font-size: 14px;
            color: #fff;
            border: none;
            margin-bottom: 8px;
        };
        > p {
            color: #fff;
            font-size: 24px;
        }
    } 
`;

const ShowHidePin = (props) => {
    const {t, i18n} = useTranslation();
    const { card } = useContext(AccountContext);
    const [openAuth, setOpenAuth] = useState(false)
    const [authenticated, setAuthenticated] = useState(false)
    const [loadingIframe, setLoadingIframe] = useState(false)

    const getPin = useQueryRequest({
        queryKey: ["ShowHidePin"],
        url:"/cardholder/cards/v2/" + card?.referenceCode + "/pin-control",
        enabled: !!card && !authenticated
    })

    useEffect(() => {
        let pinForm = document.getElementById('pin-form');
        if (authenticated) {
            pinForm.submit();
        }
    }, [authenticated]);


    return (
        <StyShowHidePin>

            { card &&
                <form id="pin-form" action={getPin.data?.pinURL} method="post" target="view-pin-iframe" onClick={() => {authenticated ? setAuthenticated(false) : setOpenAuth(true); setLoadingIframe(true)}}>
                    <input type="hidden" name="controlId" value={getPin.data?.id}/>

                    <h6>{t("Tap to show/hide your pin")}</h6>

                    { authenticated
                        ?


                            <div>
                                {loadingIframe && <Spinner noMargin height={"10px"} color={"#bdbdbd"}/>}
                                <iframe scrolling="no" id="scaled-frame" name="view-pin-iframe" onLoad={() => setLoadingIframe(false)}
                                        style={{
                                            height: "232px",
                                            width: "640px",
                                            color: "#fff",
                                            display: "block",
                                            overflow: 'hidden',
                                            border: 0,
                                            zoom: 1,
                                            WebkitTransform: "scale(0.1)",
                                            WebkitTransformOrigin: "0 0",

                                            MozTransform: "scale(0.1)",
                                            MozTransformOrigin: "0 0",

                                            OTransform: "scale(0.1)",
                                            OTransformOrigin: "0 0",

                                            msTransform: "scale(0.1)",
                                            msTransformOrigin: "0 0"
                                        }}>
                                </iframe>
                            </div>

                        :
                        <p>{"****"}</p>
                    }
                </form>
            }


            <AuthPopup
                open={openAuth}
                setOpen={setOpenAuth}
                setAuthenticated={setAuthenticated}
                title={t("Authenticate to view pin")}
                subTitle={t("You need to verify your identity with Bank ID or password to show PIN.")}
            />
        </StyShowHidePin>
    )
};

export default ShowHidePin;