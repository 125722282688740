import React from 'react';
import styled from "styled-components";
import GhostButton from "./GhostButton";
import Colors from "../constants/Colors";
import ScreenWidths from "../constants/ScreenWidths";

const StyHorizontalMenu = styled.ul`
    margin-bottom: 20px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    list-style: none;
    width: 100%;
    button {
        padding: 12px 8px 0 8px;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
        color: inherit;
        opacity: 0.5;
        @media (width < ${ScreenWidths.xs}) {
            font-size: 16px;
        }
    }
    li {
        position: relative;
    }

    .active {
        button {
            opacity: 1;
        };
        &:before {
            content : "";
            position: absolute;
            left    : 8px;
            right: 8px;
            bottom: -8px;
            height  : 1px;
            width   : calc(100% - 16px);
            border-bottom: 2px solid ${Colors.blackLightPrimary}
        }
    }
`;

const HorizontalMenu = (props) => {

    return (
        <StyHorizontalMenu {...props}>
            { props.arr?.map(item => {
                return (
                    <li key={item} className={item === props.selected ? "active" : ""}>
                        <GhostButton label={item} onClick={() => props.onSelect(item)} />
                    </li>
                )
            })}
        </StyHorizontalMenu>
    )
};

export default HorizontalMenu;