import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import jwt from "jsonwebtoken";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Button from "../components/Button";
import ScreenWidths from "../constants/ScreenWidths";
import BankId from "./BankId";

const StyLgBankId = styled.div`
    text-align: left;
    
    > :nth-child(1){
        font-size: 24px;
        font-weight: 600;
        @media (width < ${ScreenWidths.xs}) {
            font-size: 20px;
        }
    }

    > :nth-child(2){
        margin: 8px 0 28px 0;
        font-size: 16px;
        color: #4D5D70;
    }
`;

const LgBankId = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [credentialsMsg, setCredentialsMsg] = useState(" ")

    useEffect(() => {
        localStorage.removeItem('auth-token');
    },[])

    const handleAuthComplete = (collect) => {
        const decoded = jwt.decode(collect.authResponse.accessToken);
        if (decoded != null) {
            if (((decoded.applications || []).filter(app => app.includes('Cardholder'))).length > 0) {
                localStorage.setItem('auth-token', collect.authResponse.accessToken);
                navigate("/home");
            } else {
                setCredentialsMsg(t('Invalid user or user not authorized to access the application'));
            }
        }
    }

    const handleAuthFailed = (collect) => {
        if (collect.hintCode === 'expiredTransaction') {
            setCredentialsMsg(t('The BankID app is not responding. Please check that the program is started and that you have internet access. If you don\'t have a valid BankID you can get one from your bank.'))
        } else if (collect.hintCode === 'userCancel') {
            setCredentialsMsg(t('BankID authentication cancelled.'))
        } else if (collect.hintCode === 'certificateErr') {
            setCredentialsMsg(t('The BankID you are trying to use is revoked or too old. Please use another BankID or order a new one from your internet bank.'))
        } else if (collect.hintCode === 'startFailed') {
            setCredentialsMsg(t('Failed to scan the QR code'))
        } else if (collect.hintCode === 'unauthorized') {
            setCredentialsMsg(t('bankid unauthorized'));
        } else {
            setCredentialsMsg(t('BankID authentication failed.'))
        }
    }


    return (
        <StyLgBankId {...props}>
            <p>{t("Welcome to the Epassikortet")}</p>
            <p>{t("Log in to manage your top-ups, see your PIN and transaction history, order replacement cards, block your card, and similar regarding your lunch benefit.")}</p>

            <Button onClick={()=> setOpen(true)}>
                <img src={"/images/icons/bankid-white.svg"} alt={"bank id"}/>
                <div>{t("Log in with bankid")}</div>
            </Button>

            <Button variant={"outlined"} onClick={()=> navigate("/login/email")}>
                <div>{t("Log in with username and password")}</div>
            </Button>

            <BankId open={open} setOpen={setOpen} handleAuthComplete={handleAuthComplete} handleAuthFailed={handleAuthFailed}/>
        </StyLgBankId>
    )
};

export default LgBankId;