import React, {useContext, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import InputWrapper from "../components/InputWrapper";
import Button from "../components/Button";
import {useForm} from "react-hook-form";
import {SetNotificationValue} from "../hooks/NotificationContext";
import {useQueryRequest} from "../hooks/UseJsonApi";
import GhostButton from "../components/GhostButton";
import {useNavigate} from "react-router-dom";
import RegexStr from "../constants/RegexStr";

const StyChangePassword = styled.div`
    > p {
        font-weight: 600;
        margin: 24px 0 12px 0;
    }
    
    form {
        > div {
            button {
                margin: 0 0 4px 26px;
            }
        }
    }
    
`;

const ChangePassword = (props) => {
    const {t} = useTranslation()
    const { register, handleSubmit, watch, formState: { errors, isValid, isSubmitted, isDirty}, reset, resetField, getValues, setValue, setError } = useForm({ mode: "all"});
    const {setNotification} = useContext(SetNotificationValue);
    const navigate = useNavigate();

    const [oldPassShow, setOldPassShow] = useState(false)
    const [newPassShow, setNewPassShow] = useState(false)
    const [repeatPassShow, setRepeatPassShow] = useState(false)

    const changePassword = useQueryRequest({
        url: "/auth/password/change/cardholder",
        type: "POST",
        queryKey: "ChangePassword",
        body: {currentPassword: getValues("oldPassword"), newPassword: getValues("newPassword")},
        enabled: isSubmitted && isDirty,
        onSuccess: (data) => {
            if (data.success) {
                setNotification({
                    type: "success",
                    message: t(`Password updated successfully`)
                });
                reset()
            } else {
                reset({keepValues: true})
                setError("oldPassword", {type: "custom", message: t("incorrect password")});
            }
        }
    })

    return (
        <StyChangePassword {...props}>
            <p>{t("Change password")}</p>


            <form onSubmit={handleSubmit( () => {})}>

                <InputWrapper error={errors.oldPassword} label={t("Old password")} value={watch("oldPassword")} slim viewPassword={() => setOldPassShow(!oldPassShow)}>
                    <input
                        type={ oldPassShow ? "text" : "password"}
                        {...register("oldPassword", {
                            required: "This field is required",
                            pattern: { value: RegexStr.hasBlankSpace,message: "Blank spaces are not allowed"}
                        })}
                    />
                </InputWrapper>

                <div>
                    { errors?.oldPassword?.message === t("incorrect password") && <GhostButton label={t("Forgot your password?")} onClick={() => navigate("/login/email-recover")}/>}
                </div>

                <InputWrapper
                    error={errors.newPassword} label={t("New password")} value={watch("newPassword")} slim
                    viewPassword={() => setNewPassShow(!newPassShow)}
                    infoMsg={t("The password must contain at least 8 characters, 1 uppercase character, 1 lowercase character, and 1 special character.")}>
                    <input
                        type={ newPassShow ? "text" : "password"}
                        {...register("newPassword", {
                                required: t("This field is required"),
                                validate: {
                                    blankSpaces: (value) => RegexStr.hasBlankSpace.test(value) || "Blank spaces are not allowed",
                                    isUppercase: (value) => RegexStr.isUppercase.test(value) ||  t("Password does not meet requirements") + ": " + t("must contain 1 uppercase character"),
                                    isLowercase: (value) => RegexStr.isLowercase.test(value) ||  t("Password does not meet requirements") + ": " + t("must contain 1 lowercase character"),
                                    isSpecial: (value) => RegexStr.isSpecial.test(value) ||  t("Password does not meet requirements") + ": " + t("must contain 1 special character"),
                                    minLength: (value) => RegexStr.minLength.test(value) || t("Password does not meet requirements") + ": " + t("must contain minimum 8 characters"),
                                }
                            })
                        }
                    />
                </InputWrapper>

                <InputWrapper error={errors.repeatPassword} label={t("Repeat new password")} value={watch("repeatPassword")} slim viewPassword={() => setRepeatPassShow(!repeatPassShow)}>
                    <input
                        type={ repeatPassShow ? "text" : "password"}
                        {...register("repeatPassword", {required: t("This field is required"), validate: value => value === getValues("newPassword") || t("Passwords do not match") })}
                    />
                </InputWrapper>

                <Button type={"submit"} disabled={!isValid || !isDirty } loading={changePassword.isFetching}>
                    <div>{t("Change password")}</div>
                </Button>

            </form>
        </StyChangePassword>
    )
};

export default ChangePassword;