import React from 'react';

export const findValue = (obj, path) => {

    let valueFound = path.split('.').reduce((a, v) => a[v], obj)

    if (valueFound) {
        return valueFound
    } else {
        console.error("findValue function was not able to find " + path + " property in the object");
        return ""
    }
}


export const formatType = (item) => {

    const regex  = /^-[0-9]*\.?[0-9]{2}$/

    if (Date.parse(item)) {
        return new Date(item).toLocaleDateString(undefined, {year: "2-digit"  ,month: "2-digit", day: "2-digit"})

    } else if (regex.test(item)) {
        return new Intl.NumberFormat(undefined, { style: 'currency', currency: 'SEK', minimumFractionDigits: 2 }).format(item)

    } else {
        return item?.charAt(0).toUpperCase() + item?.substring(1).toLowerCase()
    }

}

