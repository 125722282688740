import React from 'react';
import styled from "styled-components";
import InputErrorMsg from "./InputErrorMsg";

const StyCheckboxWrapper = styled.label`
    > :nth-child(1) {
        margin: 16px 0;
        display: flex;
        align-items: center;
        p {
            margin-left: 12px;
        }

        input {
            width: 24px;
            height: 24px;
            accent-color: #3B9CDB;
        }

        input, p {
            cursor: pointer;
        }
    }
    
    > :nth-child(2){
        margin-top: -8px;
    }
    
    
`;

const CheckboxWrapper = (props) => {

    return (
        <StyCheckboxWrapper {...props}>
            <div>
                {props.children}
                <p>{props.label}</p>
            </div>
            {props.error && <InputErrorMsg label={props.error.message}/>}
        </StyCheckboxWrapper>
    )
};

export default CheckboxWrapper;