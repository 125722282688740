import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {SetNotificationValue} from "../hooks/NotificationContext";
import ScreenWidths from "../constants/ScreenWidths";
import {useTranslation} from "react-i18next";


const Notification = (props) => {
    const [open, setOpen] = useState(false);
    const {setNotification} = useContext(SetNotificationValue);
    const [variant, setVariant] = useState()
    const { t } = useTranslation();

    const getVariant = (type) => {
        switch (type) {
            case "error":
                return {
                    bgColor: "#FF612F",
                    icon: "/images/icons/alert_triangle.svg",
                    title: t("Error")
                };
            case "alert":
                return {
                    bgColor:  "#FFAE11",
                    icon: "/images/icons/alert_circle.svg",
                    title: t("Alert")
                };
            case "info":
                return {
                    bgColor: "#3B9CDB",
                    icon: "/images/icons/alert_circle.svg",
                    title: t("Information")
                };
            case "success":
                return {
                    bgColor: "#129649",
                    icon: "/images/icons/success_circle.svg",
                    title: t("Success")
                };
            default:
                return {
                    bgColor:  "#FFAE11",
                    icon: "/images/icons/alert_circle.svg",
                    title: t("Alert")
                }
        }
    };

    const StyNotification = styled.div`
        top: ${open ? "0px" : "-200px"};
        transition: top 0.3s ease;
        padding: 8px;
        border-radius: 8px;
        position: absolute;
        z-index: 9000;

        @media (width > ${ScreenWidths.xs}) {
            max-width: 380px;
            right: 0;
            top: ${open ? "60px" : "-200px"};
        }

        @media (width < ${ScreenWidths.xs}) {
            width: 100%;
        }

        > div {
            display: flex;
            
            > :nth-child(1) {
                width: 48px;
                background-color: ${variant?.bgColor};
                border-radius: 8px 0 0 8px;
                align-items: center;
                justify-content: center;
                display: flex;
            }
            
            > :nth-child(2) {
                width: 100%;
                background-color: #FFF;
                border-width: 2px 0;
                border-style: solid;
                border-color: #E5E8EB;

                > div {
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    padding: 12px 20px 12px 20px;

                    :nth-child(1) {
                        color: ${variant?.bgColor};
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 600;
                    }
                ;

                    :nth-child(2) {
                        color: #30363F;
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }

            > :nth-child(3) {
                border-radius: 0 8px 8px 0;
                border-width: 2px 2px 2px 0;
                border-style: solid;
                border-color: #E5E8EB;
                background-color: #FFF;

                button {
                    align-self: flex-start;
                    height: 24px;
                    width: 24px;
                    margin: 8px;
                    border: none;
                    cursor: pointer;
                }
            }
        }
    ;
    `;


    const emptyNotification = () => {
        setTimeout(() => setNotification(null), 300)
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        setVariant(getVariant(props.values.type))

        setOpen(true);
        let timer = null;
        timer = setTimeout(() => {
            setOpen(false);
            emptyNotification();
        }, props.values.timeOut ? props.values.timeOut : "10000");

        return () => clearTimeout(timer);
    }, [props.values]);

    return (
        <StyNotification {...props} >
            <div>
                <div>
                    <img src={variant?.icon} alt={"icon"}/>
                </div>

                <div>
                    <div>
                        <div>
                            {props.values.title ? props.values.title : variant?.title}
                        </div>
                        <div>
                            {props.values.message}
                        </div>
                    </div>
                </div>

                <div>
                    <button type={"button"} onClick={() => {setOpen( false); emptyNotification() }}>
                        <img src={"/images/icons/close.svg"} alt={"close button"} height={24}/>
                    </button>
                </div>
            </div>
        </StyNotification>
    )
};

export default Notification;