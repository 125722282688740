import React from 'react';
import styled from "styled-components";

const StyGhostButton = styled.button.attrs({className: 'ghost-button'})`
    
    cursor: pointer;
    width: ${props => props.align === "center" && "100%"};
    margin: 0;
    color: ${props => props.fontColor ? props.fontColor : "#3B9CDB"};
    font-family: Inter, SansaStd,serif;
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: inherit;
     *  &:hover {
        background-color: inherit;
    };
    
    > img {
        margin-left: 8px;
        height: 18px;
        width: 18px;
        border: none;
    }
    
    a {
        color: inherit;
        text-decoration: none;
        &:focus {
            color:inherit;
        }
    }
    
`;

const GhostButton = (props) => {

    return (
        <StyGhostButton onClick={props.onClick} align={props.align} fontColor={props.fontColor}>
            { props.href
                ? <a href={props.href}><div>{props.label}</div></a>
                : props.label
            }
            { props.icon && <img src={props.icon} alt="icon"/>}
        </StyGhostButton>
    )
};

export default GhostButton;