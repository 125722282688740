import React from 'react';
import {Translation} from 'react-i18next';

function executeValidators(values, validators) {
    const errors = {};
    if (validators) {
        validators.forEach(validator => {
            validator(values, errors);
        });
    }
    return errors;
}

function getValue(values, field) {
    const subFields = field.split('.');
    let nestedProperty = values;
    for (var i = 0; i < subFields.length; i++) {
        if (nestedProperty) {
            nestedProperty = nestedProperty[subFields[i]];
        } else {
            break;
        }
    }
    return nestedProperty;
}

function setError(errors, field, message) {
    const subFields = field.split('.');

    let errorObject = errors;
    for (var i = 0; i < subFields.length - 1; i++) {
        if (!errorObject[subFields[i]]) {
            errorObject[subFields[i]] = {};
        }
        errorObject = errorObject[subFields[i]];
    }

    errorObject[subFields[subFields.length - 1]] = message;
}

export const composeValidators = (validators) => {
    return (values) => { return executeValidators(values, validators); };
}

export const conditional = (conditionField, conditionMap) => {
    return (values, errors) => {
        if (getValue(values, conditionField) in conditionMap) {
            conditionMap[getValue(values, conditionField)](values, errors);
        }
    };
}

export const required = (...fields) => {
    return (values, errors) => {
        for (let field of fields) {
            const value = getValue(values, field);
            if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
                setError(errors, field, <Translation>{ (t) => t('Required') }</Translation>);
            }
        }
    };
}

export const numeric = (...fields) => {
    return (values, errors) => {
        for (let field of fields) {
            const value = getValue(values, field);
            if (value && isNaN(value)) {
                setError(errors, field, <Translation>{ (t) => t('Not a valid number') }</Translation>);
            }
        }
    };
}

export const currency = (...fields) => {
    return (values, errors) => {
        for (let field of fields) {
            const value = getValue(values, field);
            if (!value || !value.match(/(?=.*?\d)^\$?(([1-9]\d{0,2}(\.\d{3})*)|\d+)?(,\d{1,2})?$/)) {
                setError(errors, field, <Translation>{ (t) => t('currency-validation-msg') }</Translation>);
            }
        }
    };
}

export const shouldMatch = (...fields) => {
    return (values, errors) => {
        const firstValue = getValue(values, fields[0]);
        for (let field of fields) {
            const value = getValue(values, field);
            if (value !== firstValue) {
                setError(errors, field, <Translation>{ (t) => t('Not matching ' + fields[0]) }</Translation>);
            }
        }
    };
}

export const ssn = (...fields) => {
    return (values, errors) => {
        for (let field of fields) {
            const value = getValue(values, field);
            if (!value || value.replace('-', '').length !== 12 || !personnummerValidation(value)) {
                setError(errors, field, <Translation>{ (t) => t('ssn-validation-msg') }</Translation>);
            }
        }
    };
}

export const orgNumber = (...fields) => {
    return (values, errors) => {
        for (let field of fields) {
            const value = getValue(values, field);
            if (!value || (!value.match(/^\d{6}-\d{4}$/) && !value.match(/^\d{10}$/))) {
                setError(errors, field, <Translation>{ (t) => t('orgnumber-validation-msg') }</Translation>);
            }
        }
    };
}

export const postalCode = (...fields) => {
    return (values, errors) => {
        for (let field of fields) {
            const value = getValue(values, field);
            if (!value || (!value.match(/^\d{3} \d{2}$/) && !value.match(/^\d{5}$/))) {
                setError(errors, field, <Translation>{ (t) => t('postalcode-validation-msg') }</Translation>);
            }
        }
    };
}

export const email = (...fields) => {
    return (values, errors) => {
        for (let field of fields) {
            const value = getValue(values, field);
            if (!value || !value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                setError(errors, field, <Translation>{ (t) => t('email-validation-msg') }</Translation>);
            }
        }
    };
}

export const phone = (...fields) => {
    return (values, errors) => {
        for (let field of fields) {
            const value = getValue(values, field);
            if (value && !value.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)) {
                setError(errors, field, <Translation>{(t) => t('phone-validation-msg')}</Translation>);
            }
        }
    };
}

function personnummerValidation(input) {
    // Check valid length & form
    if (!input) { return false; }

    if (input.indexOf('-') === -1) {
        if (input.length === 10) {
            input = input.slice(0, 6) + "-" + input.slice(6);
        } else {
            input = input.slice(0, 8) + "-" + input.slice(8);
        }
    }
    if (!input.match(/^(\d{2})(\d{2})(\d{2})\-(\d{4})|(\d{4})(\d{2})(\d{2})\-(\d{4})$/)) { return false };

    // Clean input
    input = input.replace('-', '');
    if (input.length === 12) {
        input = input.substring(2);
    }

    // Declare variables
    var d = new Date(((!!RegExp.$1) ? RegExp.$1 : RegExp.$5), (((!!RegExp.$2) ? RegExp.$2 : RegExp.$6) - 1), ((!!RegExp.$3) ? RegExp.$3 : RegExp.$7)),
        sum = 0,
        numdigits = input.length,
        parity = numdigits % 2,
        i,
        digit;

    // Check valid date
    if (Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d.getTime())) return false;

    // Check luhn algorithm
    for (i = 0; i < numdigits; i = i + 1) {
        digit = parseInt(input.charAt(i), 10);
        if (i % 2 === parity) { digit *= 2; }
        if (digit > 9) { digit -= 9; }
        sum += digit;
    }
    return (sum % 10) === 0;
}