import React, {useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useQueryRequest} from "../hooks/UseJsonApi";

const StySwitchLanguage = styled.div`
    margin-bottom: 28px;
    
    > p {
        font-weight: 600;
        margin-bottom: 12px;
    }

    form {
        > label {
            cursor: pointer;
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 2px solid #D1D5D7;
            padding: 16px;
            border-radius: 12px;

            input[type="radio"] {
                accent-color: #30363F;
                height: 20px;
                width: 20px;
                cursor: pointer;
            }

            div {
                :nth-child(1){
                    font-weight: 600;
                };
                :nth-child(2){
                    margin-top: 4px;
                    font-size: 14px;
                }
            }
        }
    }
    
`;

const SwitchLanguage = (props) => {

    const {t, i18n} = useTranslation()

    const [language, setLanguage] = useState(i18n.language)

    const changeLanguage = useQueryRequest({
        type: "post",
        queryKey: ["changeLanguage", language],
        url:"/cardholder/language/",
        body: {lang: language}
    })

    return (
        <StySwitchLanguage {...props}>
            <p>{t("Language")}</p>

            <form>
                <label htmlFor={"sv"}>
                    <div>
                        <p>{t("Swedish")}</p>
                        <p>{"Swedish"}</p>
                    </div>
                    <input name="form" id={"sv"} type="radio" value={"sv"} checked={language === "sv"} onChange={(e) => {setLanguage(e.target.value); i18n.changeLanguage(e.target.value)}}/>
                </label>

                <label htmlFor={"en"}>
                    <div>
                        <p>{t("English")}</p>
                        <p>{"English"}</p>
                    </div>
                    <input name="form" id={"en"} type="radio" value={"en"} checked={language === "en"} onChange={(e) => {setLanguage(e.target.value); i18n.changeLanguage(e.target.value)}}/>
                </label>
            </form>
        </StySwitchLanguage>
    )
};

export default SwitchLanguage;