import React from 'react';
import config from "../../static/config";
import {useQuery} from "@tanstack/react-query";
import UseAuth from "./UseAuth";
import {useNavigate} from "react-router-dom";

const backendUrl = config.BACKEND_URL;


const useQueryRequest = ({ queryKey, url, enabled, type, body, noAuth, onSuccess, onError, noRes }) => {
    const navigate = useNavigate();
    const {isLoggedIn, renewToken} = UseAuth();

    return useQuery({
        queryKey: [type + "/" +url].concat(queryKey),
        queryFn: () => {

            if (noAuth === true){
                return fetch(backendUrl + url,
                    {
                            method: type ? type : "GET",
                            headers: { "Content-Type": "application/json"},
                            body: JSON.stringify(body)
                        }
                    ).then(res => res?.json()).then(data => { return data })
            } else if (isLoggedIn()) {
                if (noRes) {
                    return fetch(backendUrl + url,
                        {
                            method: type ? type : "GET",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${localStorage.getItem('auth-token')}`,
                            },
                            body: JSON.stringify(body)
                        })
                } else {
                    return fetch(backendUrl + url,
                        {
                            method: type ? type : "GET",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${localStorage.getItem('auth-token')}`,
                            },
                            body: JSON.stringify(body)
                        }
                    ).then(res => res?.json()).then(data => {
                        return data
                    })
                }
            } else {
                navigate("/login/bankid")
            }
        },
        enabled: enabled,
        onSuccess: (data) => {
            renewToken();
            onSuccess && onSuccess(data)
        },
        onError: (error) => {
            onError && onError(error)
        }
    })
}


export {useQueryRequest}



