import React from 'react';

export default {
    orangeAlert: "#FF612F",
    attentionAlert: "#FFAE11",
    lightFreshBlue: "#3B9CDB",
    greenSuccess: "#129649",

    blackLightPrimary: "#30363F",
    greyLightOne: "#F1F3F5",
    greyLightTwo: "#E5E8EB",
    greyLightThree: "#D1D5D7",
    greyDarkTwo: "#4D5D70",
    greyDarkThree: "#3F4652",

    indigo: "#5856D6"
}