import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const StyInputErrorMsg = styled.div`
    margin-bottom: 2px;
    display: flex;
    align-content: center;
    justify-content: ${props => props.justify && props.justify};
    
    p {
        font-weight: 600;
        color: #A80000;
    }
    img {
        margin-right: 6px;
        height: 20px;
    }
`;

const InputErrorMsg = (props) => {

    const { t } = useTranslation();

    return (
        <StyInputErrorMsg {...props}>
            <img src={"/images/icons/invalid.svg"} alt={"epassi-logo"}/>
            <p>{t(props.label)}</p>
        </StyInputErrorMsg>
    )
};

export default InputErrorMsg;