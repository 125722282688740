import React, {createContext, useState} from "react";

export const AccountContext = createContext(null);

export const AccountProvider = (props) => {

    const [account, setAccount] = useState( null);
    const [card, setCard] = useState( null);
    const [disableCalendar, setDisableCalendar] = useState(true)

    return(
        <AccountContext.Provider value={{
            account: account,
            setAccount: setAccount,
            card: card,
            setCard: setCard,
            disableCalendar: disableCalendar,
            setDisableCalendar: setDisableCalendar
        }}>
            {props.children}
        </AccountContext.Provider>
    );
};